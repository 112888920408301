var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "group-cell" },
    _vm._l(_vm.list, function (item, index) {
      return _c("div", { key: index, staticClass: "account-describe_tip" }, [
        _c("img", {
          style: { width: _vm.descStyle.iconwidth },
          attrs: { src: item.icon, alt: "" },
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            style: {
              color: _vm.descStyle.letterColor,
              fontSize: _vm.descStyle.letterFont,
            },
          },
          [_vm._v(" " + _vm._s(item.desc))]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }