<template>
    <div class="common-radio" :class="['common-radio_wrap', isColumn ? 'flex-column' : 'flex-row']">
        <div v-for="option in options" :key="option.value">
            <div class="common-radio_item" @click.stop="selectIcon(option)">
                <div class="common-radio_icon">
                    <img v-if="option.value !== value" :src="selectSvg" alt="">
                    <img v-else :src="selectedSvg" alt="">
                </div>
                <span class="common-radio_label flex1">{{ option.label }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import selectSvg from '@/vue/components/common/radio/img/select.svg'
import selectedSvg from '@/vue/components/common/radio/img/selected.svg'
export default {
    name: 'common-radio',
    props: {
        options: {
            type: Array,
            required: true
        },
        isColumn: {
            type: Boolean,
            default: true
        },
        value: [String, Number]
    },
    computed: {

    },
    data() {
        return {
            selectSvg,
            selectedSvg
        }
    },
    methods: {
        selectIcon(item) {
            this.$emit('input', item.value)
        }
    }
}
</script>

<style lang="less" scoped>
.common-radio_wrap {
    display: flex;
}

.flex-column {
    flex-direction: column;
}

.flex-row {
    flex-direction: row;
}

.common-radio_item {
    display: flex;
    justify-content: flex-start;
    margin-bottom: .41rem;
    cursor: pointer;
}

.common-radio_icon {
    width: .27rem;
    margin-right: .14rem;

    img {
        display: inline-block;
        width: 100%;
    }
}

.common-radio_label {
    text-align: left;
}

.flex1 {
    flex: 1;
}

.ommon-radio_item {
    display: flex;
}</style>