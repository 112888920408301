import { debounce } from './utils'
import Vue from 'vue'
Vue.directive("debounce", {
  bind(el, binding) {
    let val = binding.value, 
        wait = 500 , 
        immediate = false,
        type = 'click', 
        params = [],
        func
    if(val == null) return 
    if(typeof val !== 'object' && typeof val !== 'function') return 
    if(binding.arg) {
      wait = +binding.arg  
    }
    if(binding.modifiers && binding.modifiers.immediate) {
      immediate = binding.modifiers.immediate
    }
    if(typeof val === 'function') func = val;
    if(typeof val === 'object') { 
        func = val.func || function(){};
        type = val.type || 'click';
        params = val.params || [];			
    }
    el.$type = type;
    el.$handle = debounce(function(...args){
      return func.call(this, ...params.concat(args))
    }, wait, immediate)
    el.addEventListener(el.$type, el.$handle)
  },
  unbind(el) {
    el.removeEventListener(el.$type, el.$handle);
  }
});