<template>
    <div class="check-code-wrap">
        <common-input ref="code" type="number" :placeholder="placeholder" :maxlength='6' :value="value" v-on="$listeners">
        </common-input>
        <div class="check-code_msg">
            <span v-show="show" @click="requireCode" class="code_requie">Send OTP</span>
            <span v-show="!show" class="code_disable">{{ count }}</span>
        </div>
    </div>
</template>
<script>
export default {
    name: 'check-code',
    props: {
        value: {
            type: String
        },
        placeholder: {
            type: String,
            default: 'verification code'
        }
    },
    data() {
        return {
            show: true,
            count: 60,
            timer: null,
            timeCount: 60
        }
    },
    deactivated() {
        this.clear()
    },
    methods: {
        requireCode() {
            this.$emit('reCode')
        },
        downTime() {
            if (!this.timer) {
                this.show = false;
                this.timer = setInterval(()=>{
                    if (this.count > 1 && this.count <= this.timeCount) {
                        this.count--;
                    } else {
                        this.show = true;
                        this.count = 60
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000);
            }
        },
        clear() {
            this.show = true;
            this.count = 60
            clearInterval(this.timer);
            this.timer = null;
        }
    }

}
</script>
<style>
.check-code-wrap {
    display: flex;
    align-content: center;
}

.check-code_msg {
    text-align: center;
    margin-left: .1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.check-code_msg span {
    width: 1.16rem;
    height: 0.44rem;
    border-radius: 0.22rem;
    font-size: .2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.code_requie {
    border: 0.01rem solid #1483FF;
    color: #019FEB;
    align-self: center;
    align-content: center;
    cursor: pointer;
}

.code_disable {
    border: 0.01rem solid #BCC4D4;
    color: #BCC4D4;
}
</style>