var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("div", { staticClass: "mask-wrap" }, [
        _c("div", { staticClass: "banner" }, [
          _c("div", { staticClass: "banner-close", on: { click: _vm.close } }),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "banner_btn", on: { click: _vm.handleClick } },
            [_vm._v("View payment details")]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "banner-content" }, [
      _c("p", { staticClass: "title" }, [_vm._v("Important Alignment")]),
      _vm._v(" "),
      _c("p", { staticClass: "sub-title" }, [
        _vm._v("To Our Valued Pesoloan Clients,"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "content" }, [
        _vm._v(
          "\n        The Pesoloan app is temporarily unavailable on the Google Play Store due to an ongoing\n        upgrade, but it will be back soon to enhance your experience. In the meantime, iOS users\n        and loyal users can continue using the app without interruption, with all features fully\n        available.\n      "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "content" }, [
        _vm._v(
          "\n        For those who uninstalled the app and need to make payment, you may visit our official website at "
        ),
        _c(
          "a",
          {
            staticClass: "hrefStyle",
            attrs: { href: "https://www.pesoloan.net" },
          },
          [_vm._v("https://www.pesoloan.net")]
        ),
        _vm._v(
          " to check your Pesoloan account and payment details. \n        "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }