import apiProxy from '@/vue/extends/api-proxy';

export const getSignInPointIndex = (params = {}) => apiProxy.get('/SignInPoint/index',{ params }); 
export const getSignInPointRecord = (params = {}) => apiProxy.get('/SignInPoint/record',{ params }); 
export const signInPointSignin = (params = {}) => apiProxy.post('/SignInPoint/signIn', params ); 
export const getSigninList = (params = {}) => apiProxy.get('/SignInPoint/getpoint',{ params }); 
export const useSignpoint = (params = {}) => apiProxy.post('/SignInPoint/usepoint', params ); 


export const getBuriedStatistical = (params = {}) => apiProxy.get("/activity/PHBuriedStatistical", { params });
