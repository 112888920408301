<template>
    <div class="img-tips">
        <img :src="matchIconByType()" alt="">
        <div class="letter" :style="letterStyle">{{ tips }}</div>
    </div>
</template>

<script>
export default {
    name: 'img-tips',
    props: {
        letterStyle: {
            type: Object,
            default: () => {}
        },
        tips: {
            type: String,
            default: ''
        },
        iconType: {
            type: String,
            default: 'attention'
        }
    },
    methods: {
        matchIconByType() {
            const iconMap = {
                success: require('./img/success.png'),
                attention: require('./img/attention.png')
            }
            return iconMap[this.iconType]
        }
    }
}
</script>

<style lang="less" scoped>
.img-tips {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    img {
        width: 1.83rem;
        height: 1.83rem;
    }

    .letter {
        font-weight: 400;
        // margin-top: -0.17rem;
        font-size: 0.27rem;
        font-family: SFNS-Regular, SFNS;
    }
}
</style>