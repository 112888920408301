var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "common-radio",
      class: ["common-radio_wrap", _vm.isColumn ? "flex-column" : "flex-row"],
    },
    _vm._l(_vm.options, function (option) {
      return _c("div", { key: option.value }, [
        _c(
          "div",
          {
            staticClass: "common-radio_item",
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.selectIcon(option)
              },
            },
          },
          [
            _c("div", { staticClass: "common-radio_icon" }, [
              option.value !== _vm.value
                ? _c("img", { attrs: { src: _vm.selectSvg, alt: "" } })
                : _c("img", { attrs: { src: _vm.selectedSvg, alt: "" } }),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "common-radio_label flex1" }, [
              _vm._v(_vm._s(option.label)),
            ]),
          ]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }