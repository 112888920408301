<template>
    <div class="common-textarea">
        <textarea :class="['common-textarea_content', showErr ? 'active' : '']" :style="textareaStyle" :value="value" @input="inputFn"
            @blur="blurFn" @focus="focusFn" :maxlength="maxlength" :placeholder="placeholder"></textarea>
        <div v-if="showErr" class="error-tips">
            {{ errorTips }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'common-textarea',
    props: {
        placeholder: {
            type: String,
            default: 'please input'
        },
        maxlength: {
            type: Number,
            default: 500
        },
        value: {
            type: String | Number
        },
        isEmpty: {
            type: Boolean,
            default: false
        },
        errorTips: {
            type: String,
            default: 'Please input your suggestions!'
        },
        textareaStyle: {
            type: Object,
            default: ()=>{}
        }
    },
    computed: {
        showErr() {
            this.isEmpty && !this.value && this.scrollView()
            return this.isEmpty && !this.value
        }
    },
    methods: {
        inputFn(e) {
            this.$emit('input', e.target.value)
        },
        focusFn() {
            this.$emit('focus')
        },
        blurFn() {
            this.$emit('blur')
        },
        scrollView() {
            this.$nextTick(() => {
                document.querySelector('.error-tips').scrollIntoView({
                    behavior: 'smooth'
                })
            })
        },
    }
}
</script>

<style lang="less" scoped>
.common-textarea {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.common-textarea_content {
    box-sizing: content-box;
    width: 93.75%;
    height: 1.75rem;
    padding: 0.2rem;
    background: #FAFAFA;
    border-radius: 0.07rem;
    border: 0.01rem solid #EDEEF5;
    resize: none;
    outline: none;
}

.active {
    border-color: #FF564E;
}

.error-tips {
    color: #FF564E;
    font-size: .2rem;
    margin-top: .1rem;
}
</style>