/**
 * storage.js
 */

var expiresPrefix = '_t_'; //localStorage,userData Store the prefix of the cache time key
var userData = document.documentElement; //userData object
var userDataName = 'userData'; //userData There can be multiple files, currently only operate on one file

//Results of relevant compatibility checks
var localStorageSupport = true;
var userDataSupport = true;
var jsonSupport = true;

//Initialize if you need to support userData
if (!localStorageSupport && userDataSupport) {
  userData.addBehavior('#default#userData');
  userData.load('userData');
}

//Encapsulated storage object
var _S = {
  /**
   * set data(localStorage,userData)
   * @param name stored key
   * @param value stored value
   * @param expires Expiration interval (in milliseconds), the default is always valid
   * @returns {boolean} true means the storage is successful, false means the storage fails
   *
   * Additional instructions:
   * 1. A set of data can be stored at the same time. At this time, the name is a json object, and the value is the expiration time interval (the function return value at this time will be inaccurate)
   * 2. The value can be a json object, but it needs to be in a browser that supports JSON parsing, otherwise it cannot be stored
   */
  setItem: function(name, value, expires) {
    //If there is no setting content, it will be regarded as invalid setting and exit
    if (!name) {
      return false;
    }

    //Set up storage for a set of data
    if (typeof name === 'object') {
      expires = value;

      //cyclically call the storage of a single data
      for (var key in name) {
        if (name.hasOwnProperty(key)) {
          _S.setItem(key, name[key], expires);
        }
      }

      //Set up storage for individual data
    } else {
      //set value if not defined, exit
      if (typeof value === 'undefined') {
        return false;
        //When setting the json object
      } else if (typeof value === 'object') {
        //JSON objects need to be converted to strings
        if (jsonSupport) {
          value = JSON.stringify(value);
        } else {
          return false;
        }
      }

      //Expiration time processing, ms is the unit
      if (expires) {
        expires = +new Date() + expires;
      }

      //Prioritize localStorage storage
      if (localStorageSupport) {
        localStorage.setItem(name, value);
        //If there is an expiration time, you need to set
        expires && localStorage.setItem(expiresPrefix + name, expires);
        //followed by the storage of userData
      } else if (userDataSupport) {
        userData.setAttribute(name, value);
        expires && userData.setAttribute(expiresPrefix + name, expires);
        //Need to save after changing userData
        userData.save(userDataName);
      } else {
        return false;
      }
    }
    return true;
  },

  /**
   * retrieve data(localStorage,userData)
   * @param name Get the stored key
   * @returns {*} If the acquisition fails, it will return false; if the json object was previously stored, it will be converted and returned (requires the browser to support JSON parsing)
   */
  getItem: function(name) {
    var value; //return value
    var expires; //Expiration
    var jsonValue; //The value after value is parsed into json
    var now = +new Date(); //current time

    //If the key is empty, it is regarded as invalid acquisition and exits
    if (!name) {
      return false;
    }

    //Prioritize the storage acquisition of localStorage
    if (localStorageSupport) {
      value = localStorage.getItem(name);
      expires = localStorage.getItem(expiresPrefix + name);
      //followed by userData
    } else if (userDataSupport) {
      value = userData.getAttribute(name);
      expires = userData.getAttribute(expiresPrefix + name);
    }

    //Handling when data expires
    if (expires && +expires <= now) {
      _S.removeItem(name);
      return false;
    } else {
      try {
        if (jsonSupport) {
          jsonValue = JSON.parse(value);
          //For the parsed json object, it needs to be restored
          if (typeof jsonValue == 'object') {
            value = jsonValue;
          }
        }
        return value;
      } catch (e) {
        return value;
      }
    }
  },

  /**
   * delete data (localStorage,userData)
   * @param name delete stored key
   * @returns {boolean} true indicates that the delete action was successful, false indicates that the delete action was not performed
   */
  removeItem: function(name) {
    //If the key is empty, it is regarded as invalid delete and exit
    if (!name) {
      return false;
    }

    //Prioritize the deletion of localStorage
    if (localStorageSupport) {
      localStorage.removeItem(name);
      localStorage.removeItem(expiresPrefix + name);
      //followed by userData
    } else if (userData) {
      userData.removeAttribute(name);
      userData.removeAttribute(expiresPrefix + name);
      //Need to save after deleting userData
      userData.save(userDataName);
    } else {
      return false;
    }
    return true;
  },

  /**
   * set cookies
   * @param name set cookie name
   * @param value set cookie value
   * @param options Other options for cookie setting (optional), such as expires, domain, path, secure
   * @returns {boolean} true means the setting is successful, false means the setting fails
   */
  setCookie: function(name, value, options) {
    var expiresDate = new Date(); //Expiration time, the default is session
    var cookieItem = ''; //The final set cookie string

    //Exit if not valid store
    if (!name || typeof value === 'undefined') {
      return false;
    }

    //Can store multiple values at once
    if (typeof name === 'object') {
      options = value;
      //Loop through the storage of a single cookie
      for (var key in name) {
        if (name.hasOwnProperty(key)) {
          _S.setCookie(key, name[key], options);
        }
      }
    } else {
      //The default cache time is one month
      if (typeof options === 'undefined') {
        options = {};
      }
      if (typeof options.expires === 'undefined') {
        options.expires = 30 * 24 * 60 * 60 * 1000;
      }
      //name and value need to be escaped
      cookieItem += encodeURIComponent(name) + '=' + encodeURIComponent(value);

      //Treat each option separately
      if (options.domain) {
        cookieItem += '; domain=' + options.domain;
      }
      if (options.path) {
        cookieItem += '; path=' + options.path;
      }
      if (options.expires) {
        expiresDate.setTime(expiresDate.getTime() + options.expires);
        cookieItem += '; expires=' + expiresDate.toUTCString();
      }
      if (options.secure) {
        cookieItem += '; secure';
      }
      document.cookie = cookieItem;
    }
    return true;
  },

  /**
   * get cookies
   * @param name Get the cookie name
   * @returns {*} If the acquisition is successful, return the corresponding content, otherwise return false
   */
  getCookie: function(name) {
    var cookies = document.cookie; //stored cookies
    var key; //cookie key
    var keyValue; //key-value array for a single cookie

    //Invalid acquisition, exit
    if (!name) {
      return false;
    }

    //Split cookies into arrays
    cookies = cookies ? cookies.split(';') : [];
    //traverse match
    for (var i = 0; i < cookies.length; i++) {
      //Get the key-value array of a single cookie
      keyValue = cookies[i].split('=');
      //remove whitespace
      key = keyValue[0].replace(/^\s+/, '');
      //match name
      if (decodeURIComponent(key) === name) {
        return decodeURIComponent(keyValue[1]);
      }
    }
    return false;
  },

  /**
   * delete cookies
   * @param name The name of the deleted cookie
   * @returns {boolean} Returns true if the delete action is successful, and returns false if the delete action is not performed
   */
  removeCookie: function(name) {
    //Invalid delete, exit
    if (!name) {
      return false;
    }

    //Set the expiration time to delete
    return _S.setCookie(name, '', {
      expires: -1
    });
  }
};

export default _S;
