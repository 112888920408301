var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "common-textarea" }, [
    _c("textarea", {
      class: ["common-textarea_content", _vm.showErr ? "active" : ""],
      style: _vm.textareaStyle,
      attrs: { maxlength: _vm.maxlength, placeholder: _vm.placeholder },
      domProps: { value: _vm.value },
      on: { input: _vm.inputFn, blur: _vm.blurFn, focus: _vm.focusFn },
    }),
    _vm._v(" "),
    _vm.showErr
      ? _c("div", { staticClass: "error-tips" }, [
          _vm._v("\n        " + _vm._s(_vm.errorTips) + "\n    "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }