// toast
import uiToast from '@/vue/components/common/toast/toast.vue';

// dailog
import uiDialog from '@/vue/components/common/dialog/index';

import commonLoading from '@/vue/components/common/loading/loading.vue';

// mixins
import Mixins from './mixins';

// filter
import Filters from './filters';

// toast
const uiToastFactory = Vue => {
  let $vm;

  return vmOptions => {
    // the constructor
    const UiToastConstructor = Vue.extend(uiToast);
    // if there is an instance
    if (!$vm) {
      // vue instance
      $vm = new UiToastConstructor({
        // data(){ return {show:true } }
      });
      $vm.vm = $vm.$mount();
      // Insert document
      document.body.appendChild($vm.vm.$el);
    }
    // set message
    // $vm.$slots.default = [vmOptions.message]
    $vm.message = vmOptions.message;
    // show
    $vm.show = true;
    return $vm.vm;
  };
};
// dailog factory function
const uiDialogFactory = Vue => {
  return vmOptions => {
    const UiDialogConstructor = Vue.extend(uiDialog);
    // An instance is created each time it is called, and the instance is bound to the callback function
    const $vm = new UiDialogConstructor({
      // data(){ return {show:true } }
    });
    $vm.vm = $vm.$mount();
    document.body.appendChild($vm.vm.$el);
    Object.keys(vmOptions).forEach(key => {
      // Bind related events
      if (key === 'event') {
        // eslint-disable-next-line guard-for-in, no-restricted-syntax
        for (const event in vmOptions[key]) {
          $vm.$on(event, vmOptions[key][event]);
        }
      } else {
        // assign attribute value
        $vm.vm[key] = vmOptions[key];
      }
    });
    return $vm.vm;
  };
};
const commonLoadingFactory = (Vue, options) => {
  let $vm,
    $mask,
    showNumber = 0;
  const win_width = document.documentElement.clientWidth || document.body.clientWidth;
  const loading_style = win_width >= 500 ? '50px' : '0.8rem';
  console.log(loading_style);

  return vmOptions => {
    const loadingConstructor = Vue.extend(commonLoading);
    if (!$vm) {
      $vm = new loadingConstructor({});
      $vm.vm = $vm.$mount();

      $mask = document.createElement('div');
      $mask.setAttribute(
        'style',
        [
          'position:fixed',
          'top:0',
          'left:0',
          'right:0',
          'bottom:0',
          'background:rgba(0,0,0,0.2)',
          'z-index:1000'
        ].join(';')
      );
      $mask.appendChild($vm.vm.$el);
      $vm.width = loading_style;
      $vm.vm.$el.style.position = 'absolute';
      $vm.vm.$el.style.marginLeft = '-0.4rem';
      $vm.vm.$el.style.marginTop = '-0.4rem';
      $vm.vm.$el.style.left = '50%';
      $vm.vm.$el.style.left = '50%';
      $vm.vm.$el.style.top = '50%';
      // Insert Document
      // document.body.appendChild($mask);
      document.body.insertBefore($mask, document.querySelector('#app-wrap'));
    }
    // First to disappear
    if (showNumber === 0) {
      $mask.style.display = vmOptions ? 'block' : 'none';
      $vm.show = vmOptions;
    }
    // If it appears
    vmOptions ? showNumber++ : showNumber--;
    showNumber = showNumber < 0 ? 0 : showNumber;
    // The last one disappeared
    if (showNumber === 0) {
      $mask.style.display = vmOptions ? 'block' : 'none';
      $vm.show = vmOptions;
    }
    return $vm.vm;
  };
};

// plugin
export default {
  install(Vue) {
    // toast
    Vue.prototype.$ui_toast = uiToastFactory(Vue);
    // loading
    // dialog
    Vue.prototype.$ui_dialog = uiDialogFactory(Vue);
    Vue.prototype.$commonLoading = commonLoadingFactory(Vue);
    // mixins
    Vue.mixin(Mixins);

    // filter
    Filters(Vue);
  }
};
