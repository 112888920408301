<template>
    <div class="common-dialog" :class="{ fade , 'isPC': isPC }" v-if="visible" @click.stop="closeWrap">
        <div class="common-dialog_wrapper" :class="{ fade }" >
            <div v-if="showClose" class="common-dialog_close" @click="closeDialog">
                <img src="./img/close.png" alt="" srcset="">
            </div>
            <div class="common-dialog_header">
                <div class="common-dialog_title">{{ title }}</div>
            </div>
            <slot></slot>
            <div class="common-dialog_footer">
                <slot name="footer"></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'common-dialog',
    props: {
        title: {
            type: String
        },
        visible: {
            type: Boolean
        },
        showClose: {
            type: Boolean,
            default: false
        },
        isPC: {
            type: Boolean,
            default: false 
        }
    },
    data() {
        return {
            fade: false,
        }
    },
    mounted() {
        setTimeout(() => {
            this.fade = true
        }, 0)
    },
    methods: {
        closeDialog() {
            this.$emit('close')
        },
        closeWrap(e) {
            const content = document.getElementsByClassName('common-dialog_wrapper')[0]
            if(content) {
                if(!content.contains(e.target)) {
                    this.$emit('close')
                }
            }
        }
    }
}
</script>
<style  lang="less" scoped>
.common-dialog {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 8887;
    background: rgba(0, 0, 0, 0);

    &.fade {
        transition: all 0.4s;
        background: rgba(0, 0, 0, .5);
    }

    .common-dialog_wrapper {
        width: 4.56rem;
        background: #fff;
        border-radius: 0.26rem;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -60%);
        opacity: 0;
        padding: .51rem .41rem;

        &.fade {
            transition: all 0.4s;
            transform: translate(-50%, -50%);
            opacity: 1;
        }

        .common-dialog_close {
            position: absolute;
            top: .17rem;
            right: .17rem;
            width: .27rem;
            height: .27rem;
            padding: .1rem;
            img {
                width: 100%;
            }
        }

      
        .common-dialog_footer {
            text-align: center;
        }

    }

    .common-dialog_title {
        font-size: 0.27rem;
        font-family: AppleSystemUIFont;
        color: #0A1603;
        line-height: 0.41rem;
        text-align: left;
        margin-bottom: .2rem;
    }

  
}
.isPC {
    .common-dialog_wrapper {
        width: 580px;
        border-radius: 10px;
        font-size: 16px;
        padding: 40px;
    }
    .common-dialog_title {
        font-size: 16px;
        font-family: AppleSystemUIFont;
        color: #0A1603;
        text-align: left;
        margin-bottom: 6px;
        line-height: 24px;
    }
    .common-dialog_footer {
        display: flex;
        align-items: center;
        margin-top: 10px;
    }
    .dialog-btn {
        width: 300px;
        margin: 0 auto;
    }
}
</style>