<template>
    <div class="letter-cell">
        <div class="cell-img" v-if="iconUrl">
            <img :src="iconUrl">
        </div>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: 'letter-cell',
    props: {
        iconUrl: {
            type: String,
            default: () => { }
        }
    }
}
</script>
<style lang="less" scoped>
.letter-cell {
    display: flex;
    padding: .14rem 0;
}

.cell-img {
    width: 0.27rem;
    height: 0.27rem;
    img {
        height: 100%;
        display: inline-block;
    }
}
</style>