import 'core-js';
import axios from 'axios';

import Security from './security';

// rely onaxios
if (!axios) throw new Error('need axios');

const api = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    // 'x-ph-token': '70b048211fe2bc9f0b156e57a4c3678c81e09c31'
  }
});

const commonConfig = {
  headers: {},
  params: {},
  encrytoType: 0, // 0 No encryption 1 Asymmetric encryption 2 Symmetric encryption,
  key: '', // secret key,
  pKi: ''
};

api.interceptors.request.use(

  config => {
    config.headers = {
      ...config.headers,
      ...commonConfig.headers
    };

    config.data = {
      ...config.data,
      sensors_distinct_id:window.DISTINCT_ID?window.DISTINCT_ID:''
    };

    if (config.method == 'post') {
      // If it isformData
      if (Object.prototype.toString.call(config.data) == '[object FormData]') {
        // Add public parameters
        Object.keys(commonConfig.params).forEach(key => {
          config.data.append(key, commonConfig.params[key]);
        });
      } else {
        let data = {
          ...commonConfig.params,
          ...config.data,
          access_time: Math.floor(new Date() / 1000)
        };
        // encryption
        data = Security.encryptJson(
          data,
          commonConfig.encrytoType,
          commonConfig.encrytoType == 2 ? commonConfig.key : commonConfig.pKi
        );
        config.data = Object.keys(data)
          .map(k => {
            return `${encodeURIComponent(k)}=${encodeURIComponent(data[k])}`;
          })
          .join('&');
        config.headers['Content-Type'] = 'application/x-www-form-urlencoded; charset=UTF-8';
      }
    } else {
      const params = {
        ...commonConfig.params,
        ...config.params,
        access_time: Math.floor(new Date() / 1000)
      };
      // encryption
      config.params = Security.encryptJson(
        params,
        commonConfig.encrytoType,
        commonConfig.encrytoType == 2 ? commonConfig.key : commonConfig.pKi
      );
    }
    return config;
  },
  error => Promise.reject(error)
);

// returndata
api.interceptors.response.use(
  response => {
    let ret = response.data;
    if (commonConfig.encrytoType == 1 || commonConfig.encrytoType == 2) {
      try {
        ret = JSON.parse(Security.symmetricDecrypt(response.data, commonConfig.key));
      } catch (e) {
        ret = response.data;
      }
    }
    return {
      ...ret,
      _config: response.config
    };
  },
  error => Promise.reject(error)
);

export default api;

export const setCommonConfig = (key, value) => {
  if (key in commonConfig) {
    commonConfig[key] = value;
  }
};

export const setCommonParams = (key, value) => {
  commonConfig.params[key] = value;
};
