var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ph-input-wrap", style: _vm.wrapStyle }, [
    _vm.isShowSuffix
      ? _c("div", { staticClass: "ph-input_suffix" }, [
          _vm._v("\n        " + _vm._s(_vm.suffix) + " "),
          _vm.showLine ? _c("span", { staticClass: "split-line" }) : _vm._e(),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "ph-input_content" }, [
      _c("input", {
        ref: "input",
        staticClass: "ph-input",
        style: _vm.wrapStyle,
        attrs: {
          type: _vm.type,
          placeholder: _vm.placeholder,
          disabled: _vm.disabled,
          maxlength: _vm.maxlength,
          autocomplete: "off",
        },
        domProps: { value: _vm.currentValue },
        on: {
          input: _vm.handleInput,
          focus: _vm.focusInput,
          blur: function ($event) {
            _vm.isFocus = false
          },
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentValue && _vm.isFocus && _vm.clearable,
              expression: "currentValue && isFocus && clearable",
            },
          ],
          staticClass: "ph-input_close",
          on: { mousedown: _vm.clear },
        },
        [_c("img", { attrs: { src: require("./img/close.png") } })]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }