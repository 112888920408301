<template>
    <div class="group-cell">
        <div class="account-describe_tip" v-for="(item, index) in list" :key="index">
            <img :src="item.icon" :style="{width: descStyle.iconwidth }"  alt="">
            <div :style="{ color: descStyle.letterColor, fontSize: descStyle.letterFont}"> {{ item.desc }}</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'group-cell',
    props: {
        list: {
            type: Array,
            default: ()=>[]
        },
        descStyle: {
            type: Object,
            default: ()=>{
                return {
                    iconwidth: '0.34rem',
                    letterColor: '#7D7E94',
                    letterFont: '0.24rem'
                }
            }
        }
    }
}
</script>

<style lang="less" scoped> 
.group-cell {
    display: flex;
    flex-direction: column;
}
.account-describe_tip {
    display: flex;
    align-items: center;
    margin-bottom: .31rem;
    img {
        margin-right: .2rem;
    }
}
</style>