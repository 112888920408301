import CommonInput from '@/vue/components/common/input/index.vue'
import LetterCell from "@/vue/components/common/letter-cell/index.vue";
import Dialog from "@/vue/components/common/dialog/index.vue";
import CommonRadio from "@/vue/components/common/radio/index.vue";
import CommonCheck from "@/vue/components/common/check/index.vue";
import CommonTextarea from "@/vue/components/common/textarea/index.vue";
import ImgTips from '@/vue/components/common/img-tips/index.vue';
import GroupCell from '@/vue/components/common/group-cell/index.vue';
import CheckCode from "@/vue/components/common/check-code/index.vue";


const componentArr = [CheckCode, CommonInput, LetterCell, Dialog,  CommonRadio, CommonTextarea,ImgTips,GroupCell,CommonCheck]
const components = {
    install(Vue) {
        componentArr.forEach((item)=>{
            Vue.component(item.name, item)
        })
    }
}
export default components