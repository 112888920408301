var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container" }, [
    _c("img", {
      staticClass: "hand-phone",
      attrs: { src: require("../image/hand-phone.png"), alt: "" },
    }),
    _vm._v(" "),
    _c("div", { staticClass: "head-top" }, [
      _c("div", { staticClass: "head-main" }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: require("../image/icon.png"), alt: "" },
        }),
        _vm._v(" "),
        _c("div", { staticClass: "flex-bet" }, [
          _c(
            "div",
            { staticClass: "full-flow", on: { click: _vm.goFullflow } },
            [_vm._v("Apply or View Payment Details")]
          ),
          _vm._v(" "),
          _vm.isPC
            ? _c(
                "div",
                { staticClass: "cusotemer", on: { click: _vm.goCustomer } },
                [_vm._v("Customer service")]
              )
            : _vm._e(),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "partner-bg" }),
    _vm._v(" "),
    _c("div", { staticClass: "main-area" }, [
      _c("div", { staticClass: "text-area" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("p", { staticClass: "intro" }, [
          _vm._v(
            "\n        Pesoloan understands that compliance is at the heart of financial service organizations,\n        which is why we are proud to be a SEC registered company, offering a digitized mobile\n        platform.\n      "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "download-wrap" }, [
          _c("img", {
            staticClass: "apk-download",
            attrs: {
              src: "https://sgp-c2.hitechset.com/files/image/522657143985ee361.png",
            },
            on: {
              click: function ($event) {
                return _vm.$emit("download")
              },
            },
          }),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "download-wrap-small" }, [
          _c("img", {
            staticClass: "apk-download",
            attrs: {
              src: "https://sgp-c2.hitechset.com/files/image/53768818005112633.png",
            },
            on: {
              click: function ($event) {
                return _vm.$emit("download")
              },
            },
          }),
          _vm._v(" "),
          _vm._m(3),
          _vm._v(" "),
          _vm._m(4),
          _vm._v(" "),
          _vm._m(5),
        ]),
        _vm._v(" "),
        _c("span", { staticClass: "bg-wap" }),
        _vm._v(" "),
        _c("div", { staticClass: "partners" }, [
          _c("p", { staticClass: "title" }, [_vm._v("Regulatory Partners")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "partner-wrap" },
            _vm._l(_vm.imgList, function (item, index) {
              return _c("img", { key: index, attrs: { src: item, alt: "" } })
            }),
            0
          ),
          _vm._v(" "),
          _vm._m(6),
        ]),
        _vm._v(" "),
        _vm._m(7),
        _vm._v(" "),
        _vm._m(8),
        _vm._v(" "),
        _vm._m(9),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "install-step-box" }, [
      _c("p", { staticClass: "install-title" }, [
        _vm._v("How to install Pesoloan"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "step-box" }, [
        _c("img", {
          staticClass: "phone-img",
          attrs: { id: "phoneImg", src: _vm.imgs[_vm.active], alt: "" },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "step-btn-box", attrs: { id: "btnBox" } },
          _vm._l(_vm.list, function (item, index) {
            return _c("p", {
              key: index,
              staticClass: "step-btn",
              class: index == _vm.active ? "active" : "",
              domProps: { innerHTML: _vm._s(item) },
              on: {
                click: function ($event) {
                  return _vm.handleClick(index)
                },
              },
            })
          }),
          0
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h2", { staticClass: "main-title" }, [
      _vm._v("\n        Inclusive and accessible "),
      _c("span", { staticClass: "color-yellow" }, [
        _vm._v("digital financial services"),
      ]),
      _vm._v(" for\n        everyone\n      "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        attrs: {
          href: "https://apps.apple.com/ph/app/id1448866552",
          target: "_blank",
        },
      },
      [
        _c("img", {
          staticClass: "ios-play-w",
          attrs: {
            src: "https://sgp-c2.hitechset.com/files/image/53528971eafca5887.png",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        attrs: {
          href: "https://appgallery.huawei.com/#/app/C100892049",
          target: "_blank",
        },
      },
      [
        _c("img", {
          staticClass: "ios-play-w",
          attrs: {
            src: "https://sgp-c2.hitechset.com/files/image/53529083baeae1320.png",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        attrs: {
          href: "https://apps.apple.com/ph/app/id1448866552",
          target: "_blank",
        },
      },
      [
        _c("img", {
          staticClass: "ios-play-w",
          attrs: {
            src: "https://sgp-c2.hitechset.com/files/image/42729661e52b0f65.png",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        attrs: {
          href: "https://appgallery.huawei.com/#/app/C100892049",
          target: "_blank",
        },
      },
      [
        _c("img", {
          staticClass: "ios-play-w",
          attrs: {
            src: "https://sgp-c2.hitechset.com/files/image/3481381d80010c720.png",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      { attrs: { href: "https://im-client.pesoloan.ph/", target: "_blank" } },
      [
        _c("img", {
          staticClass: "google-play-w",
          attrs: {
            src: "https://sgp-c2.hitechset.com/files/image/7181646f5f3cab346.png",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "intro-wrap" }, [
      _c("p", { staticClass: "intro-title" }, [
        _vm._v("Professional Loan Procedures"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "intro-text" }, [
        _vm._v(
          "\n            Pesoloan is an online lending application operated by Masaganang Buhay Finance\n            Philippines Corporation, offering "
        ),
        _c("span", [_vm._v("hassle-free, collateral-free")]),
        _vm._v(" online\n            cash loans.\n          "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "feature-intro first-intro" }, [
      _c("div", { staticClass: "intro-box" }, [
        _c("p", { staticClass: "intro-title" }, [_vm._v("High Credit Limit")]),
        _vm._v(" "),
        _c("p", { staticClass: "intro-text" }, [
          _vm._v(
            "\n            You can borrow up to P100,000 if you keep a good credit record with us.\n          "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "intro-icon intro-icon1" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "feature-intro reverse" }, [
      _c("span", { staticClass: "intro-icon intro-icon2" }),
      _vm._v(" "),
      _c("div", { staticClass: "intro-box" }, [
        _c("p", { staticClass: "intro-title" }, [
          _vm._v("Flexible and Fast Repayment"),
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "intro-text" }, [
          _vm._v(
            "\n            We offer various loan payment channels such as GCash, 7-Eleven, MLhuillier,\n            RDPawnshop, QR Code, Bank Transfer.\n          "
          ),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "feature-intro" }, [
      _c("div", { staticClass: "intro-box" }, [
        _c("p", { staticClass: "intro-title" }, [_vm._v("Fast Application")]),
        _vm._v(" "),
        _c("p", { staticClass: "intro-text" }, [
          _vm._v(
            "3-minute application process and assessment within 24 hours."
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("span", { staticClass: "intro-icon intro-icon3" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }