<template>
    <div class="ph-input-wrap" :style="wrapStyle">
        <div v-if="isShowSuffix" class="ph-input_suffix">
            {{ suffix }} <span v-if="showLine" class="split-line"></span>
        </div>
        <div class="ph-input_content">
            <input ref="input" class="ph-input" :style="wrapStyle" :type="type" :value="currentValue"
                :placeholder="placeholder" :disabled="disabled" @input="handleInput" @focus="focusInput"
                :maxlength="maxlength" @blur='isFocus = false' autocomplete="off" />
            <div v-show='currentValue && isFocus && clearable' @mousedown="clear" class="ph-input_close">
                <img src="./img/close.png">
            </div>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'common-input',
    props: {
        placeholder: {
            type: String,
            default() {
                return 'Phone number'
            }
        },
        value: [String, Number],
        suffix: [String, Number],
        disabled: {
            type: Boolean,
            default: false
        },
        clearable: {
            type: Boolean,
            default: true
        },
        type: {
            type: String,
            default: "text"
        },
        maxlength: {
            type: Number,
            default: 50
        },
        showLine: {
            type: Boolean,
            default: false 
        },
        wrapStyle: {
            type: Object,
            default: ()=>{}
        }
    },
    data() {
        return {
            currentValue: this.value,
            isFocus: false
        };
    },
    computed: {
        isShowSuffix() {
            return !!this.suffix
        }
    },
    methods: {
        handleInput(event) {
            let value = event.target.value;
            this.currentValue = value = value.slice(0,+this.maxlength)
            this.$forceUpdate()
            this.$emit("input", value);
        },
        handleChange(event) {
            this.$emit("change", event.target.value);
        },
        clickHanle() {
            this.isFocus = true
            this.$refs.input.focus();
        },
        focusInput() {
            this.clickHanle()
            this.$emit("focus");
        },
        clear(e) {
            e.preventDefault()
            this.clickHanle()
            this.$emit('input', '')
        }
    },
    watch: {
        value(newValue, odlValue) {
            if (newValue != odlValue) {
                this.currentValue = newValue;
            }
        }
    }
};
</script>
  
<style lang="less">
.ph-input-wrap {
    display: flex;
    position: relative;
    align-items: center;
    width: 100%;
    font-size: .27rem;
}

.ph-input_content {
    display: flex;
    flex: 1;
}

.ph-input_suffix {
    padding-right: 0.2rem;
}
.ph-input_close {
    cursor: pointer;
}
.ph-input {
    width: 100%;
    flex: 1;
    outline: none;
    text-align: left;
    background: transparent;
    font-size: .27rem;
    &::placeholder {
        color:#C0C0C0 ;
    }
}

.input_close {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #c0c4cc;

    img {
        width: 0.25rm;
        height: 0.25rem;
    }
}
.split-line {
    border-right: .01rem solid #bcc4d4;
    padding-left: .06rem;
}
</style>
  