<template>
  <div class="container">
    <img class="hand-phone" src="../image/hand-phone.png" alt="" />
    <div class="head-top">
      <div class="head-main">
        <img class="logo" src="../image/icon.png" alt="" />
        <div class="flex-bet">
          <div class="full-flow" @click="goFullflow">Apply or View Payment Details</div>
          <div v-if="isPC" class="cusotemer" @click="goCustomer">Customer service</div>
        </div>
      </div>
    </div>
    <div class="partner-bg"></div>
    <div class="main-area">
      <div class="text-area">
        <h2 class="main-title">
          Inclusive and accessible <span class="color-yellow">digital financial services</span> for
          everyone
        </h2>
        <p class="intro">
          Pesoloan understands that compliance is at the heart of financial service organizations,
          which is why we are proud to be a SEC registered company, offering a digitized mobile
          platform.
        </p>
        <div class="download-wrap">
          <img
            class="apk-download"
            src="https://sgp-c2.hitechset.com/files/image/522657143985ee361.png"
            @click="$emit('download')"
          />

          <a href="https://apps.apple.com/ph/app/id1448866552" target="_blank">
            <img
              class="ios-play-w"
              src="https://sgp-c2.hitechset.com/files/image/53528971eafca5887.png"
            />
          </a>
          <a href="https://appgallery.huawei.com/#/app/C100892049" target="_blank">
            <img
              class="ios-play-w"
              src="https://sgp-c2.hitechset.com/files/image/53529083baeae1320.png"
            />
          </a>
          <!-- <a href="https://im-client.pesoloan.ph/" target="_blank">
            <img class="google-play-w" src="https://sgp-c2.hitechset.com/files/image/4272996f5f3cab743.png" />
          </a> -->
        </div>
        <div class="download-wrap-small">
          <img
            class="apk-download"
            src="https://sgp-c2.hitechset.com/files/image/53768818005112633.png"
            @click="$emit('download')"
          />

          <a href="https://apps.apple.com/ph/app/id1448866552" target="_blank">
            <img
              class="ios-play-w"
              src="https://sgp-c2.hitechset.com/files/image/42729661e52b0f65.png"
            />
          </a>
          <a href="https://appgallery.huawei.com/#/app/C100892049" target="_blank">
            <img
              class="ios-play-w"
              src="https://sgp-c2.hitechset.com/files/image/3481381d80010c720.png"
            />
          </a>
          <a href="https://im-client.pesoloan.ph/" target="_blank">
            <img
              class="google-play-w"
              src="https://sgp-c2.hitechset.com/files/image/7181646f5f3cab346.png"
            />
          </a>
        </div>
        <span class="bg-wap"></span>
        <div class="partners">
          <p class="title">Regulatory Partners</p>
          <div class="partner-wrap">
            <img v-for="(item, index) in imgList" :key="index" :src="item" alt="" />
          </div>
          <div class="intro-wrap">
            <p class="intro-title">Professional Loan Procedures</p>
            <p class="intro-text">
              Pesoloan is an online lending application operated by Masaganang Buhay Finance
              Philippines Corporation, offering <span>hassle-free, collateral-free</span> online
              cash loans.
            </p>
          </div>
        </div>
        <div class="feature-intro first-intro">
          <div class="intro-box">
            <p class="intro-title">High Credit Limit</p>
            <p class="intro-text">
              You can borrow up to P100,000 if you keep a good credit record with us.
            </p>
          </div>
          <span class="intro-icon intro-icon1"></span>
        </div>
        <div class="feature-intro reverse">
          <span class="intro-icon intro-icon2"></span>
          <div class="intro-box">
            <p class="intro-title">Flexible and Fast Repayment</p>
            <p class="intro-text">
              We offer various loan payment channels such as GCash, 7-Eleven, MLhuillier,
              RDPawnshop, QR Code, Bank Transfer.
            </p>
          </div>
        </div>
        <div class="feature-intro">
          <div class="intro-box">
            <p class="intro-title">Fast Application</p>
            <p class="intro-text">3-minute application process and assessment within 24 hours.</p>
          </div>
          <span class="intro-icon intro-icon3"></span>
        </div>
      </div>
    </div>
    <div class="install-step-box">
      <p class="install-title">How to install Pesoloan</p>
      <div class="step-box">
        <img class="phone-img" id="phoneImg" :src="imgs[active]" alt="" />
        <div class="step-btn-box" id="btnBox">
          <p
            class="step-btn"
            v-for="(item, index) in list"
            :key="index"
            :class="index == active ? 'active' : ''"
            v-html="item"
            @click="handleClick(index)"
          ></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgs: [
        'https://sgp-c2.hitechset.com/files/image/install1.png',
        'https://sgp-c2.hitechset.com/files/image/install2.png',
        'https://sgp-c2.hitechset.com/files/image/install3.png',
        'https://sgp-c2.hitechset.com/files/image/install4.png',
      ],
      list: [
        `1. Get it on Google Play`,
        '2. Register an account with your mobile number.',
        '3. Fill in information and select loan amount.',
        '4. Receive money within 5 minutes.',
      ],
      imgList: [
        'https://sgp-c2.hitechset.com/files/image/partner1.png',
        'https://sgp-c2.hitechset.com/files/image/partner2.png',
        'https://sgp-c2.hitechset.com/files/image/partner3.png',
        'https://sgp-c2.hitechset.com/files/image/partner4.png',
        'https://sgp-c2.hitechset.com/files/image/partner5.png',
        'https://sgp-c2.hitechset.com/files/image/partner6.png',
      ],
      active: 0,
    };
  },
  computed: {
    isPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      var flag = true;
      for (var v = 0; v < Agents.length; v++) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
  },
  methods: {
    handleClick(index) {
      this.active = index;
    },
    goFullflow() {
      if (this.isPC) {
        window.open('https://api-gateway.pesoloan.ph/wap/H5PcFlow?register_channel=H5#/register');
      } else {
        window.open('https://web.pesoloan.net/');
      }
    },
    goCustomer() {
      window.open('https://im-client.pesoloan.ph/');
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  width: 100%;
  flex: 1;
  background: url('../image/index-bg.png') no-repeat #fff;
  background-size: contain;

  .intro-icon1 {
    background: url('../image/intro-one.png') center no-repeat;
    background-size: 100%;
  }
  .intro-icon2 {
    background: url('../image/intro-two.png') center no-repeat;
    background-size: 100%;
  }
  .intro-icon3 {
    background: url('../image/intro-three.png') center no-repeat;
    background-size: 100%;
  }
  .color-yellow {
    color: #fca61f;
  }
  .flex-bet {
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (min-width:1200px) {
  .head-top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    background: #fff;
    z-index: 999;
  }
  .head-main {
    width: 1160px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  .full-flow {
    background: #837aff;
    color: #fff;
    border-radius: 32px;
    display: flex;
    padding: 8px 25px;
    justify-content: flex-end;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
  }
  .cusotemer {
    color: #1b1c1f;
    text-align: center;
    font-family: Poppins;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    cursor: pointer;
    margin-left: 30px;
  }
  .partner-bg {
    position: absolute;
    width: 100vw;
    height: 384px;
    background: #f4f9ff;
    top: 940px;
    left: 0;
    z-index: 1;
  }
  .main-area {
    position: relative;
    width: 1160px;
    margin: 0 auto;
    overflow: hidden;
    .text-area {
      .icon {
        display: inline-block;
        margin-top: 60px;
        width: 111px;
        height: 19px;
        background: url('../image/icon.png') center no-repeat;
        background-size: 100%;
      }
      .main-title {
        margin-top: 129px;
        width: 600px;
        font-size: 45px;
        font-family: Poppins-SemiBold;
        color: #1b1c1f;
        font-weight: bold;
        line-height: 68px;
      }
      .intro {
        width: 590px;
        margin-top: 36px;
        font-size: 18px;
        font-family: Poppins;
        font-weight: 400;
        color: #888b9b;
        line-height: 30px;
        margin-bottom: 56px;
      }
      .google-play {
        display: inline-block;
        width: 200px;
        height: 90px;
        margin-left: -14px;
        cursor: pointer;
        background: url('../image/google-play.png') center no-repeat;
        background-size: 100%;
      }
      .download-wrap {
        width: 750px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 20px;
        .google-play-w {
          display: inline-block;
          height: 66px;
          object-fit: contain;
          cursor: pointer;
        }
        .apk-download {
          height: 66px;
          object-fit: contain;
          position: relative;
          display: inline-block;
          cursor: pointer;
        }
        .ios-play-w {
          display: inline-block;
          height: 66px;
          object-fit: contain;
          cursor: pointer;
        }
      }
      .download-wrap-small {
        display: none;
      }

      .bg-wap {
        display: none;
        background: url('../image/bg-wap.png') center no-repeat;
        background-size: 100%;
      }
    }
    .partners {
      margin-top: 360px;
      width: 100%;
      position: relative;
      z-index: 2;
      padding: 72px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #f4f9ff;
      .title {
        color: #1b1c1f;
        text-align: center;
        font-family: Montserrat;
        font-size: 30px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-bottom: 40px;
      }
      .partner-wrap {
        width: 932px;
        padding-bottom: 28px;
        display: flex;
        align-items: center;
        gap: 16px;
        img {
          width: 142px;
          object-fit: contain;
        }
      }
      .intro-wrap {
        width: 932px;
        text-align: left;
        .intro-title {
          color: #282004;
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 26px; /* 144.444% */
          padding-bottom: 6px;
        }
        .intro-text {
          color: #808691;
          font-family: Poppins;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px; /* 142.857% */
          span {
            color: #7167fa;
          }
        }
      }
    }
    .feature-intro {
      margin-top: 113px;
      display: flex;
      align-items: center;
      .intro-box {
        margin-left: 98px;
        .intro-title {
          width: 260px;
          font-size: 26px;
          font-family: Poppins-SemiBold;
          font-weight: bold;
          color: #202020;
          line-height: 45px;
        }
        .intro-text {
          width: 344px;
          font-size: 14px;
          font-family: Poppins;
          font-weight: 400;
          color: #59647c;
          line-height: 27px;
        }
      }
      .intro-icon {
        display: inline-block;
        margin-left: 46px;
        width: 308px;
        height: 245px;
      }
    }
    .reverse {
      .intro-box {
        margin-left: 78px;
      }
      .intro-icon {
        margin-left: 76px;
      }
    }
  }
  .install-step-box {
    margin-top: 90px;
    padding-bottom: 28px;
    background: #f4f9ff;
    .install-title {
      padding-top: 90px;
      text-align: center;
      font-size: 30px;
      font-family: Poppins-SemiBold;
      font-weight: bold;
      color: #1b1c1f;
      line-height: 45px;
    }
    .step-box {
      width: 1080px;
      margin: 60px auto 0;
      display: flex;
      justify-content: center;
      margin-top: 60px;
      .phone-img {
        width: 370px;
        height: 473px;
      }
      .step-btn-box {
        margin-top: 11px;
        .step-btn {
          display: block;
          width: 448px;
          height: fit-content;
          padding: 16px 22px;
          box-sizing: border-box;
          margin-bottom: 30px;
          background: #dad7ff;
          border-radius: 14px;
          line-height: 45px;
          font-size: 17px;
          color: #ffffff;
          font-family: Poppins;
          cursor: pointer;
        }
        .active {
          background: linear-gradient(90deg, #837aff 0%, #6b61ed 100%);
        }
      }
    }
  }
  .hand-phone {
    height: 984px;
    position: absolute;
    top: 131px;
    right: 0;
    z-index: 99;
  }
}
@media screen and (max-width:1200px) {
  .head-top {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 999;
    height: 0.8rem;
    padding: 0 0.2rem;
    box-sizing: border-box;
  }
  .head-main {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  .logo {
    width: 2rem;
  }
  .full-flow {
    border-radius: 0.32rem;
    background: #837aff;
    color: #fff;
    display: flex;
    padding: 0.1rem 0.24rem;
    justify-content: center;
    align-items: center;
    font-size: 0.2rem;
  }
  .partner-bg {
    position: absolute;
    width: 100vw;
    height: 6.4rem;
    background: #f4f9ff;
    top: 15.6rem;
    left: 0;
    z-index: 1;
  }
  .main-area {
    position: relative;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    background: url('../image/index-bg2.png') no-repeat #fff;
    background-size: contain;
    .text-area {
      padding: 0.85rem 0.34rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon {
        display: inline-block;
        width: 1.19rem;
        height: 0.2rem;
        background: url('../image/icon.png') center no-repeat;
        background-size: 100%;
        position: relative;
        left: -2.2rem;
      }
      .main-title {
        margin: 0.51rem auto 0.34rem;
        width: 4.2rem;
        font-size: 0.34rem;
        line-height: 0.51rem;
        font-family: Poppins-SemiBold;
        font-weight: bold;
        color: #333333;
        text-align: center;
      }
      .intro {
        margin: 0 auto;
        width: 4.81rem;
        font-size: 0.24rem;
        font-family: Poppins;
        font-weight: 400;
        text-align: center;
        color: #333333;
        line-height: 0.36rem;
        padding-bottom: 0.38rem;
      }
      .google-play {
        display: block;
        width: 2.7rem;
        height: 1.06rem;
        margin: 1.2rem auto 0;
        background: url('../image/google-play.png') center no-repeat;
        background-size: 100%;
      }
      .download-wrap {
        display: none;
      }
      .download-wrap-small {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 0.2rem;
        img {
          margin-bottom: 0.16rem;
          cursor: pointer;
        }
        .apk-download {
          width: 2.6rem;
          object-fit: contain;
          display: block;
        }
        .google-play-w {
          display: block;
          width: 2.6rem;
          object-fit: contain;
          margin-bottom: 0.2rem;
        }
        .ios-play-w {
          display: block;
          width: 2.6rem;
          object-fit: contain;
        }
      }

      .bg-wap {
        display: block;
        width: 5.1rem;
        height: 6.13rem;
        margin: auto;
        background: url('../image/bg-wap.png') center no-repeat;
        background-size: 100%;
      }
    }
    .partners {
      width: 100%;
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        color: #1b1c1f;
        text-align: center;
        font-family: Montserrat;
        font-size: 0.28rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        padding-bottom: 0.61rem;
      }
      .partner-wrap {
        width: 100%;
        padding-bottom: 0.34rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        gap: 0.2rem;
        img {
          width: 2.42rem;
          object-fit: contain;
        }
      }
      .intro-wrap {
        width: 100%;
        text-align: left;
        padding-left: 0.6rem;
        .intro-title {
          color: #282004;
          font-family: Poppins;
          font-size: 0.3rem;
          font-style: normal;
          font-weight: 600;
          line-height: 0.44rem; /* 144.444% */
          padding-bottom: 0.14rem;
        }
        .intro-text {
          color: #808691;
          font-family: Poppins;
          font-size: 0.24rem;
          font-style: normal;
          font-weight: 400;
          line-height: 0.34rem; /* 142.857% */
          span {
            color: #7167fa;
          }
        }
      }
    }
    .feature-intro {
      margin-top: 0.68rem;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      .intro-box {
        margin: 0;
        .intro-title {
          width: 100%;
          margin-top: 0.34rem;
          font-size: 0.24rem;
          font-family: Poppins-SemiBold;
          font-weight: bold;
          color: #202020;
          text-align: center;
          line-height: 0.38rem;
        }
        .intro-text {
          width: 4.85rem;
          margin-top: 0.17rem;
          font-size: 0.2rem;
          font-family: Poppins;
          font-weight: 400;
          text-align: center;
          color: #59647c;
          line-height: 0.31rem;
        }
      }
      .intro-icon {
        margin: 0;
        width: 2.8rem;
        height: 2.22rem;
      }
    }
    .reverse {
      flex-direction: column;
    }
  }
  .install-step-box {
    padding: 0.85rem 0.34rem;
    background: #f4f9ff;
    .install-title {
      text-align: center;
      font-size: 0.27rem;
      font-family: Poppins-SemiBold;
      font-weight: bold;
      color: #1b1c1f;
      line-height: 0.41rem;
    }
    .step-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0.51rem;
      .phone-img {
        width: 5.05rem;
        height: 6.47rem;
      }
      .step-btn-box {
        margin-top: -0.2rem;
        .step-btn {
          display: block;
          width: 100%;
          height: fit-content;
          padding: 0.27rem;
          box-sizing: border-box;
          margin-bottom: 0.27rem;
          background: #dad7ff;
          border-radius: 0.14rem;
          line-height: 0.31rem;
          font-size: 0.19rem;
          color: #ffffff;
          font-family: Poppins;
          cursor: pointer;
        }
        .active {
          background: linear-gradient(90deg, #837aff 0%, #6b61ed 100%);
        }
      }
    }
  }
  .hand-phone {
    display: none;
  }
}
</style>
