
//  immediate: true  right exec  false last exec 
export const debounce = (fn, delay = 1000, immediate = true) => {
    let timer = null
    return function () {
        if (timer) {
            clearTimeout(timer)
        }
        let now = immediate && !timer
        timer = setTimeout(() => {
            timer = null
            !immediate ? fn.call(this) : null
        }, delay)
        now ? fn.call(this) : null
    }
}


/**
 * close window
 */
export const closeWindow = () => {
    var userAgent = navigator.userAgent
    if (userAgent.indexOf('Firefox') !== -1 || userAgent.indexOf('Chrome') !== -1) {
        window.location.replace('about:blank')
    } else {
        window.opener = null
        window.open('', '_self')
    }
    window.close()
}
export const isPC = () => {
    const userAgentInfo = navigator.userAgent;
    const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
    let flag = true;
    for (let v = 0; v < Agents.length; v += 1) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
            flag = false;
            break;
        }
    }
    return flag;
};

/**
 * useremInitialization settings for
 */
const root = document.documentElement;
function getClientWidth() {
    return Math.min(root.clientWidth, 1440);
}
export const resetRem = () => {
    root.style.fontSize = '80px';
    const base = isPC() ? 24.3 : 6.4;
    root.style.fontSize = `${getClientWidth() / base}px`;
    window.addEventListener('resize', () => {
        root.style.fontSize = `${getClientWidth() / base}px`;
    });
};


