// toolkit
import infoCollectHelper from "./infoCollect";

const getDecimal = (decimal, defaultDecimal) => {
  //For the number of decimal places not passed in or the number of decimal places passed in isundefined,Set Default Decimal Digits
  if (typeof decimal === 'undefined') {
    decimal = defaultDecimal;
  }
  //Convert decimal places to numbers
  decimal = +decimal;
  //For non numeric and negative numbers,Set as default decimal place
  if (isNaN(decimal) || decimal < 0) {
    decimal = defaultDecimal;
  }
  //Convert to Integer,Avoid decimal problems
  return parseInt(decimal, 10);
};

/**
 * Keep values down(Negative numbers are reserved upward),Decimal digits can be specified,The separator of every three bits of the integer part can be set
 * https://github.com/tjuking/floor-toFixed
 * @param amount {string|number} Value passed in
 * @param [decimal=2] {number} Keep Decimal Places
 * @param [separator=","] {string} Separator
 * @returns {string} Processed string data
 */
const formatAmount = (amount, decimal, separator, needNegativeCarry) => {
  /************ Variable declaration ************/

  let isNegative; //judgeamountIdentification bit of whether it is a negative number
  let integerPart; //amountInteger part of
  let decimalPart; //amountDecimal part of
  let integerArr; //Single character array of integer part
  let decimalArr; //Single character array of decimal parts
  let integerArrLength; //Integer single character array length
  let decimalArrLength; //Length of decimal single character array
  let withSepIntegerArr; //Delimited integer single character array
  let firstDotIndex; //amountPosition of the first decimal point in
  let defaultDecimal = 0; //Two decimal places are reserved by default
  let defaultSeparator = ','; //The default delimiter is comma
  let negativeCarry = false; //Identification of whether to carry a negative number
  let negativeIntegerCarry = false; //Identification of whether to carry an integer in case of a negative number
  let i; //Use when cycling

  /************ numerical valueamounthandle ************/

  //aboutundefined null 0 falseUnify as"0"
  if (!amount) {
    amount = '0';
  }
  //amountYou need to convert it to a string for subsequent operations(Not used hereamount+""How)
  if (typeof amount !== 'string') {
    amount = new String(amount).toString();
  }
  //Judge whether it is a negative number by leading symbols
  isNegative = amount.indexOf('-') === 0;
  //Remove all non numeric and non decimal placeholders
  amount = amount.replace(/[^\d\.]/g, '');
  //Get the position of the first decimal point
  firstDotIndex = amount.indexOf('.');
  //If there is a decimal point, it needs to be divided into two parts to set the integer part and the decimal part
  if (firstDotIndex >= 0) {
    integerPart = amount.substring(0, firstDotIndex) || '0';
    decimalPart =
      amount.substr(firstDotIndex + 1, amount.length).replace(/\./g, '') || '0';
  } else {
    //Setting without decimal point
    integerPart = amount;
    decimalPart = '0';
  }
  //Convert integer part and decimal part to single character
  integerArr = integerPart.split('');
  decimalArr = decimalPart.split('');
  integerArrLength = integerArr.length;
  decimalArrLength = decimalArr.length;

  /************ Decimal Digitsdecimalhandle ************/

  decimal = getDecimal(decimal, defaultDecimal);

  /************ Separatorseparatorhandle ************/

  //For a delimiter not passed in or passed inundefined,Set as default delimiter
  if (typeof separator === 'undefined') {
    separator = defaultSeparator;
  } else if (typeof separator !== 'string') {
    //Non string delimiters need to be converted to strings
    separator = new String(separator).toString();
  }

  /************ Core logic processing ************/

  //Insufficient decimal places,Need to complete
  if (decimal > decimalArrLength) {
    for (i = 0; i < decimal - decimalArrLength; i++) {
      decimalArr.push('0');
    }
  } else if (decimal < decimalArrLength) {
    //Too many decimal places,Need to remove
    //If it is a negative number,Value needs to be reserved upwards
    if (isNegative && needNegativeCarry) {
      //Whether the decimal value after traversing the required decimal places is greater than0
      for (i = decimal; i < decimalArrLength; i++) {
        //If greater than0Then decimal place rounding is required
        if (decimalArr[i] > 0) {
          negativeCarry = true;
          break;
        }
      }
      //If decimal place rounding is required
      if (negativeCarry) {
        //If there is no decimal place to be reserved,Then the mark needs to carry to the integer digit,And set the decimal place array to null
        if (decimal === 0) {
          negativeIntegerCarry = true;
          decimalArr = [];
        } else {
          //Decimal carry processing
          for (i = decimal - 1; i >= 0; i--) {
            //If the current value is9,Set as0
            if (decimalArr[i] === '9') {
              decimalArr[i] = '0';
              //If it is the first decimal,Then carry to integer digit
              if (i === 0) {
                negativeIntegerCarry = true;
              }
            } else {
              //no9,Then add1Exit the cycle after
              decimalArr[i] = +decimalArr[i] + 1 + '';
              break;
            }
          }
        }
        //If you need to carry to the integer digit
        if (negativeIntegerCarry) {
          //If the current value is9,Set as0
          for (i = integerArrLength - 1; i >= 0; i--) {
            if (integerArr[i] === '9') {
              integerArr[i] = '0';
              //If it is the first integer,You need to insert it first1
              if (i === 0) {
                integerArr.unshift('1');
                integerArrLength = integerArr.length;
                break;
              }
            } else {
              //no9,Then add1Exit the cycle after
              integerArr[i] = +integerArr[i] + 1 + '';
              break;
            }
          }
        }
      }
    }
    //Decimal truncated to the specified number of digits
    decimalArr = decimalArr.slice(0, decimal);
  }

  /************ Correction of returned results ************/

  //If a separator is set,Separator needs to be inserted
  if (separator) {
    //Initialize empty array
    withSepIntegerArr = [];
    for (i = 0; i < integerArrLength; i++) {
      //Add separator every three digits,The first and last digits are not added
      if (
        i % 3 === integerArrLength % 3 &&
        i !== integerArrLength - 1 &&
        i !== 0
      ) {
        withSepIntegerArr.push(separator);
      }
      //Insert corresponding element
      withSepIntegerArr.push(integerArr[i]);
    }
    //Assign back to integer single character array
    integerArr = withSepIntegerArr;
  }
  //Adjust the values of integer and decimal parts according to the modified array elements
  integerPart = integerArr.join('');
  decimalPart = decimalArr.join('');
  //If it is not necessary to reserve the decimal, set the result to the integer value directly
  if (decimal === 0) {
    amount = integerPart;
  } else {
    //The result value when decimals need to be retained is a combination of two parts
    amount = integerPart + '.' + decimalPart;
  }
  //If it is a negative number,Need to add a minus sign
  if (isNegative) {
    amount = '-' + amount;
  }

  //Return result value
  return amount;
};

/**
 * Convert timestamp to time
 * @param ns time stamp
 * @param formatA format yyyy-MM-dd hh:mm:ss
 * @returns {string}
 */
const toTime = (ns, formatA) => {
  var time, format, o;
  time = new Date(ns * 1000);
  format = formatA || 'yyyy-MM-dd';
  o = {
    'M+': time.getMonth() + 1,
    'd+': time.getDate(),
    'h+': time.getHours(),
    'm+': time.getMinutes(),
    's+': time.getSeconds(),
    'q+': Math.floor((time.getMonth() + 3) / 3),
    S: time.getMilliseconds(),
  };
  if (/(y+)/.test(format)) {
    format = format.replace(
      RegExp.$1,
      (time.getFullYear() + '').substr(4 - RegExp.$1.length)
    );
  }
  for (var k in o) {
    if (o.hasOwnProperty(k)) {
      if (new RegExp('(' + k + ')').test(format)) {
        format = format.replace(
          RegExp.$1,
          RegExp.$1.length == 1
            ? o[k]
            : ('00' + o[k]).substr(('' + o[k]).length)
        );
      }
    }
  }
  return format;
};

// Judge whether it isjson object If yes and return
const getJsonOrigin = (jsonString, type = 'object') => {
  if (!jsonString) {
    return jsonString;
  }
  try {
    let obj = JSON.parse(jsonString);
    return typeof obj === type ? obj : jsonString;
  } catch (e) {
    return jsonString;
  }
};

// uriEncodecode 1 valuecode 2 keycode 3 key\valueBoth codes
const encodePostParams = (data = {}, type = 1) => {
  return Object.keys(data)
    .map((key) => {
      let _key = type == 2 || type == 3 ? encodeURIComponent(key) : key;
      let _value =
        type == 1 || type == 3
          ? encodeURIComponent(data[key] ? data[key] : '')
          : data[key];
      return `${_key}=${_value}`;
    })
    .join('&');
};

// Remove the uselesskey
const omit = (obj, keyArr) => {
  let o = {};
  Object.keys(obj).forEach((key) => {
    if (keyArr.indexOf(key) < 0) {
      o[key] = obj[key];
    }
  });
  return o;
};

// retainkey
const pick = (obj, keyArr) => {
  let o = {};
  Object.keys(obj).forEach((key) => {
    if (keyArr.indexOf(key) > -1) {
      o[key] = obj[key];
    }
  });
  return o;
};

// wait for
const wait = (time = 1000) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, time);
  });
};

// Time zone calculation
const dateConfigTimeZone = (timeValue) => {
  const date = new Date(timeValue); // UTCtime (1970-1-1Milliseconds to date + Local time vsGMTMinute difference)
  return date.getTime() + date.getTimezoneOffset() * 60 * 1000; // return (UTCtime + Time zone difference)
};

// Get default language
const getDefaultLanguage = (locale, i18nSet = [], defaultLocale) => {
  // If within the set
  if (i18nSet.indexOf(locale) > -1) {
    return locale;
  } else {
    // If there is a default language
    if (defaultLocale && i18nSet.indexOf(defaultLocale) > -1) {
      return defaultLocale;
    } else if (i18nSet[0]) {
      return i18nSet[0];
    }
  }
  // All misses Default English
  return 'en';
};

// Multi language Add Namespace compatibleh5 and weex
const addI18nNamespace = (namespace, language) => {
  return Object.keys(language)
    .map((locale) => {
      return {
        [locale]: {
          [namespace]: language[locale],
        },
      };
    })
    .reduce((x, y) => {
      return {
        ...x,
        ...y,
      };
    });
};

// Format and add units for each country amount
const formatAmountWithUnit = (unit, unitPos, amount, decimal = 0) => {
  let arr = [formatAmount(amount, decimal)];
  if (unitPos === 'pre') {
    arr.unshift(unit);
  } else {
    arr.push(unit);
  }
  return arr.join(' ');
};

// Four digit segmentation of bank card
const splitStringSameInterval = (bankaccount = '', split = ' ', num = 4) => {
  bankaccount = bankaccount.toString();
  let times = Math.floor(bankaccount.length / num);

  return Array.apply(null, { length: ++times })
    .map((item, index) => {
      return bankaccount.slice(index * num, (index + 1) * num);
    })
    .join(split);
};

// Remove space
const removeStringSplit = (string = '', split = '\\s') => {
  let reg = new RegExp(`${split}`, 'g');
  return string.replace(reg, '');
};

// add os prefix
const addPre = (key) => {
  return 'os_' + key;
};

// Simple confusion
const simpleEncrypt = (str) => {
  return btoa(
    str
      .split('')
      .map((char) => char.charCodeAt(0) << 2)
      .join('-')
  );
};

const simpleDecrypt = (str) => {
  return atob(str)
    .split('-')
    .map((number) => String.fromCharCode(Number(number) >> 2))
    .join('');
};

const replaceStr = (oldStr, replaceStr, replaceRule) => {
  return oldStr.replace(replaceRule, replaceStr);
};

const showPosition = (position) => {
  return position;
};

const tryGeolocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition);
  }
};

// Report information processing
const infoHandler = (obj) => {
  let newobj = {};
  for (var key in obj) {
    if (
      typeof obj[key] != 'function' &&
      (obj[key] != null || obj[key] != undefined)
    ) {
      newobj[key] = obj[key];
    }
  }
  // console.log("newobj", newobj);
  return newobj;
};

const handleOrientation = () => {
  var x = event.beta; // In degree in the range [-180,180)
  var y = event.gamma; // In degree in the range [-90,90)
  console.log('event', event);
};

const infoCollectAddEventLister = () => {
  return infoCollectHelper.infoCollectAddEventLister()
};

// Get reporting information
const infoCollect = async () => {
  const infoCollectMsg = await infoCollectHelper.infoCollect();
  return infoCollectMsg;
};

const versionConl = (curVersion, conVersion, calc) => {
  let calaA = curVersion
    .split('')
    .filter(item => item != '.')
    .join('');
  let calaB = conVersion
    .split('')
    .filter(item => item != '.')
    .join('');
  switch (calc) {
    case '=':
      return calaA == calaB;
      break;
    case '>':
      return calaA > calaB;
      break;
    case '<':
      return calaA < calaB;
      break;
    case '>=':
      return calaA >= calaB;
      break;
    case '<=':
      return calaA <= calaB;
      break;
  }
};

export default {
  formatAmount,
  toTime,
  getJsonOrigin,
  encodePostParams,
  omit,
  pick,
  wait,
  dateConfigTimeZone,
  getDefaultLanguage,
  addI18nNamespace,
  formatAmountWithUnit,
  splitStringSameInterval,
  removeStringSplit,
  addPre,
  simpleEncrypt,
  simpleDecrypt,
  replaceStr,
  infoCollect,
  infoCollectAddEventLister,
  versionConl
};
