class EventBus {
  constructor() {
    this._callbacks = {};
    this.id = new Date() / 1000;
  }

  on(eventName, callback) {
    const en = eventName.toLowerCase();

    if (!this._callbacks[en]) {
      this._callbacks[en] = [];
    }
    this._callbacks[en].push(callback);
    return this;
  }

  // eslint-disable-next-line consistent-return
  trigger(eventName, ...rest) {
    const en = eventName.toLowerCase();

    if (!en || !this._callbacks[en]) {
      return this;
    }
    this._callbacks[en].forEach(callback => {
      callback.apply(this, rest);
    });
  }
}

export default new EventBus();
