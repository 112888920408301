var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "common-check common-check_wrap" }, [
    _c(
      "div",
      {
        class: ["common-check_icon", _vm.isPc ? "common-check_icon_pc" : ""],
        on: { click: _vm.selectIcon },
      },
      [
        !_vm.value
          ? _c("img", { attrs: { src: require("./img/select.svg"), alt: "" } })
          : _c("img", {
              attrs: { src: require("./img/selected.png"), alt: "" },
            }),
      ]
    ),
    _vm._v(" "),
    _c(
      "span",
      { staticClass: "common-check_label flex1" },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }