import Vue from 'vue';
// import '@/assets/css/public.less';
// plugins
import Plugins from '@/vue/extends/plugins';
import Components from '@/vue/components/common/index';
import IndexPh from '@/vue/view/index-ph-official/index.vue';

// router-wrap
import '@/vue/extends/directive';
Vue.use(Plugins);
Vue.use(Components);
Vue.use(SURFUI, { size: 'small' });
const el = '#index-ph-official';
(async () => {
  try {
    new Vue({
      components: {
        IndexPh,
      },
    }).$mount('#index-ph-official');
  } catch (e) {
    console.log(e, 'error');
  }
})();
