var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("footer", { staticClass: "footer" }, [
    _c("div", { staticClass: "footer-box" }, [
      _c("div", { staticClass: "footer-content" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "download-box" }, [
          _c("p", { staticClass: "cu-title" }, [_vm._v("Download")]),
          _vm._v(" "),
          _c("p", { staticClass: "flex-start" }, [
            _c("img", {
              staticClass: "download-icon",
              attrs: {
                src: "https://sgp-c2.hitechset.com/files/image/footer-google.png",
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("download")
                },
              },
            }),
          ]),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _vm._m(2),
        ]),
        _vm._v(" "),
        _vm._m(3),
        _vm._v(" "),
        _c("div", { staticClass: "download-box-wap" }, [
          _c("p", { staticClass: "cu-title" }, [_vm._v("Download")]),
          _vm._v(" "),
          _c("div", { staticClass: "flex-wrap" }, [
            _c("p", [
              _c("img", {
                staticClass: "download-icon",
                attrs: {
                  src: "https://sgp-c2.hitechset.com/files/image/footer-google.png",
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("download")
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _vm._m(4),
            _vm._v(" "),
            _vm._m(5),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(6),
    ]),
    _vm._v(" "),
    _c("div", { class: ["tip", _vm.show ? "tip-show" : ""] }, [
      _vm._m(7),
      _vm._v(" "),
      _c("div", { staticClass: "tip__confirm", on: { click: _vm.close } }, [
        _vm._v("OK"),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "contact-us" }, [
      _c("p", { staticClass: "cu-title" }, [_vm._v("Contact us")]),
      _vm._v(" "),
      _c("p", { staticClass: "cu-text" }, [
        _vm._v("Email: support@pesoloan.ph"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "cu-text" }, [
        _vm._v("Hotline: 0917 626 0130 | 0917 620 6116"),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "cu-text" }, [
        _vm._v("9am - 6pm (Monday - Sunday)"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "bottom" }, [
        _vm._v(
          "\n          Pesoloan is operated by Masaganang Buhay Finance Philippines Corporation, a licensed\n          financing company with "
        ),
        _c("span", { staticClass: "last-light" }, [_vm._v("SEC")]),
        _vm._v(" Registration\n          "),
        _c("span", { staticClass: "last-light" }, [_vm._v("No. CS201824008")]),
        _vm._v(" and Certificate of Authority\n          "),
        _c("span", { staticClass: "last-light" }, [_vm._v("No. 1185")]),
        _vm._v(
          ". Always study the terms and conditions and the\n          disclosure statement before proceeding with any loan transaction.\n        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "flex-start" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://apps.apple.com/ph/app/id1448866552",
            target: "_blank",
          },
        },
        [
          _c("img", {
            staticClass: "download-icon",
            attrs: {
              src: "https://sgp-c2.hitechset.com/files/image/6802197207932d444.png",
              alt: "",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "flex-start" }, [
      _c(
        "a",
        {
          attrs: {
            href: "https://appgallery.huawei.com/#/app/C100892049",
            target: "_blank",
          },
        },
        [
          _c("img", {
            staticClass: "download-icon",
            attrs: {
              src: "https://sgp-c2.hitechset.com/files/image/6802186b4a2e4c895.png",
              alt: "",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("p", { staticClass: "cu-title" }, [_vm._v("Legal")]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "cu-text",
          attrs: { href: "https://im-client.pesoloan.ph/" },
        },
        [_vm._v("Online service")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "cu-text",
          attrs: {
            href: "https://api-gateway.pesoloan.ph/wap/agreement?name=privacy_agreement&system=pesoloan",
          },
        },
        [_vm._v("Privacy agreement")]
      ),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "cu-text",
          attrs: {
            href: "http://api-gateway.pesoloan.ph/wap/agreement?system=pesoloan&name=pesoloan_website_privacy_policy",
          },
        },
        [_vm._v("Website Privacy Policy")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "flex-start" }, [
      _c(
        "a",
        { attrs: { href: "https://apps.apple.com/ph/app/id1448866552" } },
        [
          _c("img", {
            staticClass: "download-icon",
            attrs: { src: require("../image/footer-ios.png"), alt: "" },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "flex-start" }, [
      _c(
        "a",
        { attrs: { href: "https://appgallery.huawei.com/#/app/C100892049" } },
        [
          _c("img", {
            staticClass: "download-icon",
            attrs: {
              src: "https://sgp-c2.hitechset.com/files/image/6802186b4a2e4c895.png",
              alt: "",
            },
          }),
        ]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "copyright" }, [
      _vm._v("\n      Copyright © "),
      _c("span", { staticClass: "copyright-year" }),
      _vm._v(" Pesoloan. All Rights Reserved.\n    "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "tip__text" }, [
      _vm._v(
        "\n      The Only purpose of Pesoloan Official website is to showcase the products and services\n      offered to the filipino customers. It does not collect any customer information in any form.\n      Kindly visit the\n      "
      ),
      _c(
        "a",
        {
          staticClass: "tip__text__link",
          attrs: {
            href: "http://api-gateway.pesoloan.ph/wap/agreement?system=pesoloan&name=pesoloan_website_privacy_policy",
            target: "_blank",
          },
        },
        [_vm._v("Website Privacy Policy")]
      ),
      _vm._v(".\n    "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }