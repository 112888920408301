// toolkit

const showPosition = (position) => {
  return position;
};
// Collection address
const tryGeolocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition);
  }
};

// Report information processing
const infoHandler = (obj) => {
  let newobj = {};
  for (var key in obj) {
    if (
      typeof obj[key] != "function" &&
      (obj[key] != null || obj[key] != undefined)
    ) {
      newobj[key] = obj[key];
    }
  }
  // console.log("newobj", newobj);
  return newobj;
};

const getmb = (size) => {
  return Math.floor(size / 1024 / 1024, 4) + "mb";
};
const getsec = (time) => {
  return time / 1000 + "s";
};
const IsMobile = () => {
  let userAgent = navigator.userAgent,
    Agents = [
      "Android",
      "iPhone",
      "SymbianOS",
      "Windows Phone",
      "iPad",
      "iPod",
    ];
  return Agents.some((i) => {
    return userAgent.includes(i);
  });
};
const handleOrientation = () => {
  var x = event.beta; // In degree in the range [-180,180)
  var y = event.gamma; // In degree in the range [-90,90)
};

let infoGather = {};

const infoCollectAddEventLister = () => {
  // Pointer
  window.addEventListener("deviceorientation", (deviceorientationEvent) => {
    infoGather.DeviceOrientation = infoHandler(deviceorientationEvent);
  });
  // Screen orientation API
  window.addEventListener("orientationchange", function () {
    var orientation = window.screen.orientation;
    infoGather.ScreenOrientation = infoHandler(orientation);
  });
};

// Get reporting information
const infoCollectData = () => {
  let infoGather = {};
  var canvas = document.createElement("canvas");
  var gl = canvas.getContext("webgl"); // === canvas.getContext('experimental-webgl');
  var debugInfo = gl.getExtension("WEBGL_debug_renderer_info");

  const audio = document.createElement("audio");
  const video = document.createElement("video");

  let audioType = [
    "audio/midi",
    "audio/mpeg",
    "audio/webm",
    "audio/ogg",
    "audio/wav",
  ];
  let videoType = ["video/webm", "video/ogg"];
  let acObj = {};
  let vcObj = {};
  let jsfontsObj = {};

  audioType.forEach((item) => {
    if (audio.canPlayType(item)) {
      acObj[item] = audio.canPlayType(item);
    }
  });
  videoType.forEach((item) => {
    if (video.canPlayType(item)) {
      vcObj[item] = video.canPlayType(item);
    }
  });
  let fonts = [
    "PingFang SC",
    "simsun",
    "cursive",
    "monospace",
    "serif",
    "sans-serif",
    "fantasy",
    "default",
    "Arial",
    "Arial Black",
    "Arial Narrow",
    "Arial Rounded MT Bold",
    "Bookman Old Style",
    "Bradley Hand ITC",
    "Century",
    "Century Gothic",
    "Comic Sans MS",
    "Courier",
    "Courier New",
    "Georgia",
    "Gentium",
    "Impact",
    "King",
    "Lucida Console",
    "Lalit",
    "Modena",
    "Monotype Corsiva",
    "Papyrus",
    "Tahoma",
    "TeX",
    "Times",
    "Times New Roman",
    "Trebuchet MS",
    "Verdana",
    "Verona",
  ];
  let d = new Detector();
  fonts.forEach((i) => {
    jsfontsObj[i] = d.detect(i);
  });

  return new Promise(function (resolve) {
    infoGather.url = window.location.href;
    infoGather.currentTime = Date.parse(new Date())
      ? Date.parse(new Date())
      : "";
    infoGather.cookie = document.cookie ? document.cookie : "";
    infoGather.userAgent = navigator.userAgent ? navigator.userAgent : "";
    infoGather.appName = navigator.appName ? navigator.appName : "";
    infoGather.appversion = navigator.appversion ? navigator.appversion : "";
    infoGather.connection = navigator.connection ? navigator.connection : "";
    infoGather.geolocation = navigator.geolocation ? navigator.geolocation : "";
    infoGather.language = navigator.language ? navigator.language : "";
    infoGather.platform = navigator.platform ? navigator.platform : "";
    infoGather.product = navigator.product ? navigator.product : "";
    infoGather.productSub = navigator.productSub ? navigator.productSub : "";
    infoGather.screenHeight = window.screen.height ? window.screen.height : "";
    infoGather.screenWidth = window.screen.width ? window.screen.width : "";
    infoGather.screenColorDepth = window.screen.colorDepth
      ? window.screen.colorDepth
      : "";
    infoGather.screenDevice = window.screen.pixelDepth
      ? window.screen.pixelDepth
      : "";
    infoGather.cpu = navigator.oscpu ? navigator.oscpu : "";
    infoGather.dnt = navigator.doNotTrack ? navigator.doNotTrack : "";
    infoGather.dm = navigator.deviceMemory ? navigator.deviceMemory : "";
    infoGather.hc = navigator.hardwareConcurrency
      ? navigator.hardwareConcurrency
      : "";
    infoGather.orient = screen.orientation ? screen.orientation : "";
    infoGather.hasSS = window.sessionStorage ? 1 : 0;
    infoGather.hasLS = window.localStorage ? 1 : 0;
    infoGather.hasIDB = window.indexedDB ? 1 : 0;
    infoGather.hasODB = window.openDatabase ? 1 : 0;
    infoGather.activex = window.ActiveXObject ? 1 : 0;
    infoGather.touch = "ontouchstart" in document.documentElement ? 1 : 0;
    infoGather.availW = innerWidth || screen.availWidth;
    infoGather.availH = innerHeight || screen.availHeight;
    infoGather.browserW = outerWidth;
    infoGather.browserH = outerHeight;
    infoGather.historyCount = history.length;
    infoGather.timeZone = new Date().getTimezoneOffset() / 60;
    infoGather.vendor = navigator.vendor;
    infoGather.rendGPUManufacturer = gl.getParameter(
      debugInfo.UNMASKED_VENDOR_WEBGL
    );
    infoGather.rendGPUModel = gl.getParameter(
      debugInfo.UNMASKED_RENDERER_WEBGL
    );
    infoGather.plugins = navigator.plugins ? navigator.plugins : "";
    infoGather.isJs = 1;
    infoGather.pr = window.devicePixelRatio ? window.devicePixelRatio : "";
    infoGather.ac = acObj;
    infoGather.vc = vcObj;
    infoGather.jsfonts = jsfontsObj;
    infoGather.referrer = "";
    infoGather.p_time = "";
    infoGather.bs = "";
    infoGather.bmem = "";
    infoGather.mimes = navigator.mimeTypes ? navigator.mimeTypes : "";

    let timer = null;
    let runCheck = () => {
      // Since callingloadWhenloadEventEndWill be0
      // Then judge,If the value is not0,Just callperfDataMethod to collect performance indicators
      if (performance.timing.loadEventEnd) {
        clearTimeout(timer);
        const perfDataTiming = perfData(performance.timing);

        // Print
        infoGather.performanceTiming = {};
        infoGather.performanceTiming.timing = performance.timing
          ? performance.timing
          : {};
        infoGather.performanceTiming = Object.assign(
          infoGather.performanceTiming,
          perfDataTiming
        );
      } else {
        timer = setTimeout(runCheck, 100);
      }
    };
    if (document.readyState == "complete") {
      runCheck();
    } else {
      window.addEventListener("load", runCheck, false);
    }
    function perfData(performanceTiming) {
      return {
        usedJSHeapSize: getmb(
          window.performance &&
            window.performance.memory &&
            window.performance.memory.usedJSHeapSize
            ? window.performance.memory.usedJSHeapSize
            : ""
        ),
        redirectTime: getsec(
          performanceTiming.redirectEnd - performanceTiming.redirectStart
        ),
        domTime: getsec(
          performanceTiming.domComplete - performanceTiming.domLoading
        ),
        loadTime: getsec(
          performanceTiming.loadEventEnd - performanceTiming.navigationStart
        ),
        unloadTime: getsec(
          performanceTiming.unloadEventEnd - performanceTiming.unloadEventStart
        ),
        requestTime: getsec(
          performanceTiming.responseEnd - performanceTiming.requestStart
        ),
        currentTime: new Date().getTime(),
      };
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        var latitude = position.coords.latitude;
        var longitude = position.coords.longitude;
        infoGather.geolocation.latitude = latitude;
        infoGather.geolocation.longitude = longitude;
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }

    if (IsMobile()) {
      var md = new MobileDetect(window.navigator.userAgent);
      const mdArr = [
        "mobile",
        "phone",
        "tablet",
        "userAgent",
        "userAgents",
        "os",
      ];

      infoGather.mobileDetect = {};
      mdArr.forEach((item) => {
        infoGather.mobileDetect[item] = md[item]();
      });
    }
    // Get battery status;
    if (navigator.getBattery && navigator.getBattery()) {
      navigator
        .getBattery()
        .then(function (battery) {
          if (battery) {
            infoGather.BatteryManager = infoHandler(battery);
          }
        })
        .catch((error) => console.log("error:", error));
    }
    // Access to geographic information
    // let geoInfo = tryGeolocation();
    // if (geoInfo) {
    //   infoGather.GeolocationCoordinates = infoHandler(geoInfo);
    // }

    // network information
    var connection =
      navigator.connection ||
      navigator.mozConnection ||
      navigator.webkitConnection;

    if (connection) {
      infoGather.connection = infoHandler(connection);
    }

    // jurisdiction
    infoGather.permissions = {};
    var permissionsArr = [
      "geolocation",
      "notifications",
      "push",
      "midi",
      "camera",
      "microphone",
      "speaker-selection",
      "device-info",
      "background-fetch",
      "background-sync",
      "bluetooth",
      "persistent-storage",
      "ambient-light-sensor",
      "accelerometer",
      "gyroscope",
      "magnetometer",
      "clipboard-read",
      "clipboard-write",
      "display-capture",
      "nfc",
    ];
    if (navigator.permissions) {
      permissionsArr.forEach((item) => {
        navigator.permissions
          .query({ name: item })
          .then(function (result) {
            if (result.state) {
              infoGather.permissions[item] = result.state;
            }
          })
          .catch((error) => console.log("error:", error));
      });
    }
    setTimeout(function () {
      resolve(infoGather);
    }, 500);
  });
};

const infoCollect = async (cb) => {
  const infoGather = await infoCollectData().then((infoGather) => {
    return infoGather;
  });
  return infoGather;
};

export default {
  infoCollect,
  infoCollectAddEventLister,
};
