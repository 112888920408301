var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "check-code-wrap" },
    [
      _c(
        "common-input",
        _vm._g(
          {
            ref: "code",
            attrs: {
              type: "number",
              placeholder: _vm.placeholder,
              maxlength: 6,
              value: _vm.value,
            },
          },
          _vm.$listeners
        )
      ),
      _vm._v(" "),
      _c("div", { staticClass: "check-code_msg" }, [
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.show,
                expression: "show",
              },
            ],
            staticClass: "code_requie",
            on: { click: _vm.requireCode },
          },
          [_vm._v("Send OTP")]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: !_vm.show,
                expression: "!show",
              },
            ],
            staticClass: "code_disable",
          },
          [_vm._v(_vm._s(_vm.count))]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }