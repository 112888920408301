var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "img-tips" }, [
    _c("img", { attrs: { src: _vm.matchIconByType(), alt: "" } }),
    _vm._v(" "),
    _c("div", { staticClass: "letter", style: _vm.letterStyle }, [
      _vm._v(_vm._s(_vm.tips)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }