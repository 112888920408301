var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "common-loading", style: _vm.outerStyle }, [
    _c("div", { staticClass: "outer", style: _vm.outerStyle }, [
      _c("img", {
        style: _vm.outerStyle,
        attrs: { src: require("./image/outer.png") },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "inner", style: _vm.outerStyle }, [
      _c("img", {
        style: _vm.outerStyle,
        attrs: { src: require("./image/inner.png") },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }