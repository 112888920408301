<template>
  <div class="index-main" ref="main">
    <mask-comp @gofull="goFullFlow" @download="handleDownload"></mask-comp>
    <container-comp @download="handleDownload"></container-comp>
    <footer-comp @download="handleDownload"></footer-comp>
  </div>
</template>

<script>
import ContainerComp from './components/container.vue';
import maskComp from './components/mask.vue';
import FooterComp from './components/footer.vue';
export default {
  components: {
    ContainerComp,
    maskComp,
    FooterComp,
  },
  methods: {
    isPC() {
      const userAgentInfo = navigator.userAgent || '';
      const Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
      let flag = true;
      for (let v = 0; v < Agents.length; v += 1) {
        if (userAgentInfo.indexOf(Agents[v]) > 0) {
          flag = false;
          break;
        }
      }
      return flag;
    },
    getDeviceModel() {
      const userAgent = navigator.userAgent || '';
      if (/iPhone/.test(userAgent)) {
        return 'iPhone';
      }
      if (/Android/.test(userAgent)) {
        return 'Android';
      }
      return 'Unknown';
    },
    goFullFlow() {
      const isPc = this.isPC();
      if(isPc) {
        window.location.href = 'https://api-gateway.pesoloan.ph/wap/H5PcFlow?register_channel=H5#/register'
      } else {
        window.location.href = 'https://api-gateway.pesoloan.ph/wap/H5Flow?register_channel=H5#/register'
      }
    },
    handleDownload() {
      const isPc = this.isPC();
      if (isPc || !navigator || !navigator.userAgent) {
        window.location.href =
          'https://play.google.com/store/apps/details?id=ph.com.peso.fast.loan.android';
        return;
      }
      setTimeout(() => {
        const device = this.getDeviceModel();
        if (device == 'iPhone') {
          window.location.href = 'https://apps.apple.com/ph/app/id1448866552';
        } else {
          window.location.href =
            'https://play.google.com/store/apps/details?id=ph.com.peso.fast.loan.android';
        }
      }, 1000);
      window.location.href = 'peso://www.peso.com/app/main?tabIndex=0';
    },
  },
};
</script>

<style lang="less">
.index-main {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
  position: relative;
}
</style>
