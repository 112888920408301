var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.visible
    ? _c(
        "div",
        {
          staticClass: "common-dialog",
          class: { fade: _vm.fade, isPC: _vm.isPC },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.closeWrap.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "common-dialog_wrapper", class: { fade: _vm.fade } },
            [
              _vm.showClose
                ? _c(
                    "div",
                    {
                      staticClass: "common-dialog_close",
                      on: { click: _vm.closeDialog },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: require("./img/close.png"),
                          alt: "",
                          srcset: "",
                        },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "common-dialog_header" }, [
                _c("div", { staticClass: "common-dialog_title" }, [
                  _vm._v(_vm._s(_vm.title)),
                ]),
              ]),
              _vm._v(" "),
              _vm._t("default"),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "common-dialog_footer" },
                [_vm._t("footer")],
                2
              ),
            ],
            2
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }