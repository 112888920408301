<template>
	<div class="common-loading" :style="outerStyle">
        <div class="outer" :style="outerStyle">
            <img src="./image/outer.png" :style="outerStyle">
        </div>
        <div class="inner" :style="outerStyle">
            <img src="./image/inner.png" :style="outerStyle">
        </div>
	</div>
</template>

<script>

	export default {
		name:'commonLoading',
		props:{
			width:{
				type:String,
				default(){
					return '1.8rem';
				}
			},
			show:{
				type:Boolean,
				default(){
					return false;
				}
			}
		},
		data(){
			return {
				lang:'en'
			}
        },
        computed:{  
            outerStyle(){
				return {
					height:this.width,
					width:this.width
				}
            },
        },

        methods:{
            getUrl(){
            	return localStorage.getItem('loadingInnerPh');
            },
            getOuterUrl(){
                return localStorage.getItem('loadingOuterPh');
            }
        },
        created() {
            if(localStorage.getItem('os_lang')){
                this.lang = localStorage.getItem('os_lang')
            }
        },
		watch:{

		}
	}
</script>

<style lang="less">
	.common-loading{
		position: relative;
		margin:0 auto;
		.outer{
			animation: 1s loadingrotate linear infinite;
			-webkit-animation: 1s loadingrotate linear infinite;
		}
		.inner{
			position: absolute;
			top:0;
			left:0;
		}
		#svg-font {
	        font-size: 26px;
	        fill: #fff;
	        text-anchor: middle;
	        font-family: Arial;
	     }
	}
	@keyframes loadingrotate{
		0% {
			transform:rotateZ(0deg)
		}
		100% {
			transform:rotateZ(360deg)
		}
	}
	@-webkit-keyframes loadingrotate{
		0% {
			-webkit-transform:rotateZ(0deg)
		}
		100% {
			-webkit-transform:rotateZ(360deg)
		}
	}
</style>