var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "letter-cell" },
    [
      _vm.iconUrl
        ? _c("div", { staticClass: "cell-img" }, [
            _c("img", { attrs: { src: _vm.iconUrl } }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }