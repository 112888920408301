import { render, staticRenderFns } from "./mask.vue?vue&type=template&id=6d30ea33&scoped=true"
import script from "./mask.vue?vue&type=script&lang=js"
export * from "./mask.vue?vue&type=script&lang=js"
import style0 from "./mask.vue?vue&type=style&index=0&id=6d30ea33&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6d30ea33",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data1/c23_workspace/os_fe_ph_pesoloan_ifp_h5/os_fe_ph_pesoloan_ifp_h5/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6d30ea33')) {
      api.createRecord('6d30ea33', component.options)
    } else {
      api.reload('6d30ea33', component.options)
    }
    module.hot.accept("./mask.vue?vue&type=template&id=6d30ea33&scoped=true", function () {
      api.rerender('6d30ea33', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/vue/view/index-ph-official/components/mask.vue"
export default component.exports