var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "main", staticClass: "index-main" },
    [
      _c("mask-comp", {
        on: { gofull: _vm.goFullFlow, download: _vm.handleDownload },
      }),
      _vm._v(" "),
      _c("container-comp", { on: { download: _vm.handleDownload } }),
      _vm._v(" "),
      _c("footer-comp", { on: { download: _vm.handleDownload } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }