<template>
  <div class="common-check common-check_wrap">
    <div
      :class="['common-check_icon', isPc ? 'common-check_icon_pc' : '']"
      @click="selectIcon"
    >
      <img v-if="!value" src="./img/select.svg" alt="" />
      <img v-else src="./img/selected.png" alt="" />
    </div>
    <span class="common-check_label flex1">
      <slot></slot>
    </span>
  </div>
</template>

<script>
export default {
  name: "common-check",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    isPc: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultVal: this.value,
    };
  },
  methods: {
    selectIcon() {
      this.defaultVal = !this.value;
      this.$emit("input", this.defaultVal);
    },
  },
};
</script>

<style lang="less" scoped>
.common-check_wrap {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.common-check_item {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.41rem;
}

.common-check_icon {
  width: 0.27rem;
  margin-right: 0.14rem;

  img {
    display: inline-block;
    width: 100%;
  }
}
.common-check_icon_pc {
  width: 14px;
  height: 14px;
  margin-right: 7px;
  float: left;
  margin-top: 2px;
}
.common-check_label {
  text-align: left;
  font-size: 0.2rem;
  font-family: SFNS-Regular, SFNS;
  font-weight: 400;
  color: #7d7e94;
}

.flex1 {
  flex: 1;
}

.ommon-check_item {
  display: flex;
}
</style>
