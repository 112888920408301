import helper from '@/vue/extends/helper';

// filter
export default Vue => {
  // amount
  Vue.filter('amount_format', (...rest) => helper.formatAmount(...rest));

  // date
  Vue.filter('date_format', (...rest) => helper.toTime(...rest));
  Vue.filter('dateFormat', function(value,str='/') {
    if (!value || Number(value) === 0) return '--';
    const date = new Date(Number(value) * 1000);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${month}${str}${day}${str}${year}`;
});
Vue.filter('date_format', (...rest) => helper.toTime(...rest));

Vue.filter('dateFormaTime', function(timestamp) {
  const date = new Date(timestamp * 1000);
  const options = { month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
  
  return date.toLocaleString('en-US', options);
})
  // amount_unit
  Vue.filter('amount_unit_format', (amount, unit, unitPos, decimal) => {
    if (decimal && !Number.isInteger(+amount)) {
			decimal = 2
		} else {
			decimal = 0
		}
    return helper.formatAmountWithUnit(unit, unitPos, amount, decimal);
  });

  // thousands
	Vue.filter("amout_thousands", (value) => {
		if (!value) return;
		return Number(value).toLocaleString();
	});
};
