/* eslint-disable no-underscore-dangle, no-unused-expressions */
import eventCenter from './event-center.js';
import { getBuriedStatistical } from "@/vue/api/coin-sign";
import storage from "@/vue/extends/storage";
export default {
  methods: {
    // wait
    _wait(time) {
      return new Promise(resolve => {
        setTimeout(() => {
          resolve('wait:done');
        }, time);
      });
    },
    // countdown
    async _commonFetch(api, needToast = true, needLoading = true) {
      const error_codes = [
        '11211300',
        '11211301',
        '11211302',
        '11211303',
        '11211304',
        '11213106' // have registered
      ];
      try {
        // show loading
        needLoading && this.$commonLoading(true);
        const ret = await api;
        if (ret.error_no !== 0) {
          if (ret.error_no == 112100100 && ret.data.validate) {
            const key = Object.keys(ret.data.validate)[0];
            needToast && this.$ui_toast({ message: ret.data.validate[key] });
          } else {
            needToast && this.$ui_toast({ message: ret.error_message });
          }
          if (error_codes.indexOf(ret.error_no.toString()) > -1) {
            // redirect
            const regionConfig = P.data("region_config");
            if (
              regionConfig &&
              regionConfig.project &&
              (regionConfig.project == "phLogin")
            ) {
              this.$router.push("/login");
            }
            eventCenter.trigger("refresh-swap");
          }
        }
        needLoading && this.$commonLoading(false);
        return ret;
      } catch (e) {
        needLoading && this.$commonLoading(false);
        let apiUrl = '';
        let res = {};
        try {
          apiUrl = e.config.url;
          res = e.response;
          console.log(apiUrl, res);
        } catch (err) {
          console.log(err);
        }
        needToast && this.$ui_toast({ message: 'Net error' });
        return null;
      }
    },
    getBuried(node) {
      // const { source } = this.$route.query
      const source  = new URL(location.href).searchParams.get('source') || ''
      const app_version = storage.getCookie("u_app_version")
          ? storage.getCookie("u_app_version")
          : '2.2.1';
      const d_id_store = storage.getCookie("u_d_id_store")
          ? storage.getCookie("u_d_id_store")
          : 0;
      const access_time = Math.floor(new Date() / 1000);
      const language = storage.getCookie("u_language")
          ? storage.getCookie("u_language")
          : "en";
    
      let params = {
          app_version,
          d_id_store,
          access_time,
          language,
          node,
          source
      };
      this._commonFetch(getBuriedStatistical(params), true, false);
    },
   
    // router.push
    _routerPush(path) {
      // historyRecord.indexOf(path) < 0 && historyRecord.push(path)
      return this.$router.push(path);
    },
    // compare  version
    comparisonVersion(version = '', currentVersion = '') {
      if (
        !/^[0-9]+\.[0-9]+\.[0-9]+$/i.test(version) ||
        !/^[0-9]+\.[0-9]+\.[0-9]+$/i.test(currentVersion)
      ) {
        return false;
      }
      const versionNum = version.split('.').join('');
      const currentVersionNum = currentVersion.split('.').join('');

      return currentVersionNum - versionNum;
    },
    goPage(url,needPop=true) {
        const inBrowser = typeof window !== 'undefined';
        const UA = inBrowser && window.navigator.userAgent.toLowerCase();
        const isIOS = (UA && /iphone|ipad|ipod|ios/.test(UA));
        if (window.osweex && window.osweex.goPage) {
            if(isIOS &&  window.osweex.goPageNeedDeleteCurrentVC ) {
                window.osweex.goPageNeedDeleteCurrentVC(url)
            }else {
                window.osweex.goPage(url);
                if(needPop) {
                  window.osweex.pop();
                }
            }
        }
    },
    getParamsStr(obj){
      return Object.keys(obj).map(function(k) {
        return k + '=' + obj[k];
      }).join('&');
    },
    debounce(func, delay) {
      let timer;
      return (...args) => {
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          func.apply(this, args);
        }, delay);
      };
    },
    getUrl(url) {
      const search = location.search;
      const redirectData = {
        path:'',
        host:'peso://www.peso.com',
        params: {}
      };
      const urlHash = location.hash
      if( search[0] === '?' ){
        search.slice(1).split('&').forEach(function(item) {
          const kv = item.split('=');
          if( kv[0] === 'path' && kv[1]){
            const weexPath = search.slice(1).split('path=')[1]
            redirectData.path = decodeURIComponent(weexPath);
          }else if( ['url'].indexOf(kv[0]) > -1 ){
            const newUrl = search.slice(1).split('url=')[1] + urlHash
            redirectData.params[kv[0]]= decodeURIComponent(newUrl);
          }
        });
      }
      const str = this.getParamsStr(redirectData.params);
      
      return redirectData.host + (redirectData.path ? redirectData.path : '/dispatch/webview') +  (str ? '?' + str : `?url=${url}`);
    },
  }
};
